import React from 'react';

const SnapLinePath = (props) => {
  return (
    <path
      className="snapline-path"
      strokeDasharray="5,5"
      stroke="white"
      strokeOpacity="1"
      strokeWidth="3"
      strokeLinecap="butt"
      fill="none"
      {...props}
    />
  );
};

export default SnapLinePath;

