import {action, computed, observable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight} from '@fortawesome/free-solid-svg-icons';
import ReactSelect from 'react-select';
import {UncontrolledTooltip} from 'reactstrap';

import {actionAlignComponent} from '../../../../../display/components/action/actionAlignComponent';

import './alignEntitiesButtons.scss';

import {
  ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT,
  ALIGN_TOP, ALIGN_MIDDLE, ALIGN_BOTTOM,
  DISTRIBUTE_HORIZONTALLY, DISTRIBUTE_VERTICALLY,
  ALIGNMENT_TARGET_CANVAS,
  ALIGNMENT_TARGET_SELECTION
} from '../../../../../constants/entityOptionConstants';
import {getIsPositionLocked} from '../../../../../utils/dragDropHelper';
import {getZonesArray} from '../../../../../display/ecs/entityHelper';

const ALIGNMENT_TARGET_OPTIONS = [
  {
    label: 'Canvas',
    value: ALIGNMENT_TARGET_CANVAS,
  },
  {
    label: 'Selection',
    value: ALIGNMENT_TARGET_SELECTION,
  },
];

const ALIGN_BUTTONS = [
  {
    label: 'Top',
    icon: faAlignLeft,
    iconRotation: 90,
    style: ALIGN_TOP,
  },
  {
    label: 'Left',
    icon: faAlignLeft,
    style: ALIGN_LEFT,
  },
  {
    label: 'Middle',
    icon: faAlignCenter,
    iconRotation: 90,
    style: ALIGN_MIDDLE,
  },
  {
    label: 'Center',
    icon: faAlignCenter,
    style: ALIGN_CENTER,
  },
  {
    label: 'Bottom',
    icon: faAlignRight,
    iconRotation: 90,
    style: ALIGN_BOTTOM,
  },
  {
    label: 'Right',
    icon: faAlignRight,
    style: ALIGN_RIGHT,
  },
];

const DISTRIBUTE_BUTTONS = [
  {
    id: 0,
    label: 'Vertically',
    icon: faAlignJustify,
    style: DISTRIBUTE_VERTICALLY,
  },
  {
    id: 1,
    label: 'Horizontally',
    icon: faAlignJustify,
    iconRotation: 90,
    style: DISTRIBUTE_HORIZONTALLY,
  },
];

/**
 * The AlignEntitiesButtons component.
 */
export class AlignEntitiesButtons extends React.Component {
  /**
   * Selected align type
   *
   * @type {string}
   */
  @observable alignmentTarget = ALIGNMENT_TARGET_CANVAS;

  /**
   * Triggered when the component is first mounted
   */
  componentDidMount() {
    const {game, single} = this.props;

    runInAction('preset alignment target', () => {
      this.alignmentTarget = single
        ? ALIGNMENT_TARGET_CANVAS
        : game.alignmentTarget;
    });
  }

  /**
   * Changes the plan align type.
   *
   * @param {{}} changeData
   */
  @action onChangeAlignmentTarget = ({value}) => {
    const {game} = this.props;

    game.setAlignmentTarget(value);
    this.alignmentTarget = value;
  }

  /**
   * Determine if more than 2 zones are active
   *
   * @returns {boolean}
   */
  @computed get moreThanTwoZonesActive() {
    const {game} = this.props;

    const zones = getZonesArray(game.allActiveEntities);

    return zones.length > 2;
  }

  /**
   * Aligns entities according to style
   *
   * @param {{}} item
   */
  onButtonClick = (item) => {
    const {game} = this.props;

    const entityIds = game.allActiveEntities.filter((activeEntity) => {
      return !getIsPositionLocked(activeEntity);
    }).map((activeEntity) => {
      return activeEntity.get('id');
    });
    const actionParams = {
      entityId: entityIds,
    };

    game.addAction(actionParams, actionAlignComponent(item.style, this.alignmentTarget));
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {id, single} = this.props;

    return (
      <div id={id} className="align-entities-buttons">
        {(!single) && (
          <div className="d-flex align-items-center mb-3">
            <span className="mr-3">
              Align to
            </span>
            <ReactSelect
              className="theme-header theme-input-white flex-grow-1"
              classNamePrefix="react-select"
              isClearable={false}
              isMulti={false}
              onChange={this.onChangeAlignmentTarget}
              options={ALIGNMENT_TARGET_OPTIONS}
              value={ALIGNMENT_TARGET_OPTIONS.find((option) => option.value === this.alignmentTarget)}
            />
          </div>
        )}

        <div className="container-fluid p-0 mb-3">
          <div className="row no-gutters">
            {(ALIGN_BUTTONS.map((item) => (
              <div
                className="col-6"
                key={item.label}
              >
                <button
                  type="button"
                  className="btn btn-sm btn-light w-100 px-4 text-left"
                  onClick={() => this.onButtonClick(item)}
                >
                  <FontAwesomeIcon
                    className="mr-2"
                    icon={item.icon}
                    rotation={item.iconRotation}
                  />
                  {item.label}
                </button>
              </div>
            )))}
          </div>
        </div>

        {(!single) && (
          <div>
            <div className="font-weight-bold small mb-2">
              Distribute
            </div>

            <div className="container-fluid p-0">
              <div className="row no-gutters">
                {(DISTRIBUTE_BUTTONS.map((item) => (
                  <div
                    className="col-6"
                    key={item.id}
                  >
                    {(!this.moreThanTwoZonesActive) && (
                      <UncontrolledTooltip
                        placement="top"
                        target={`distribute-button-${item.id}`}
                      >
                        Select more than 2 layers to distribute
                      </UncontrolledTooltip>
                    )}
                    <button
                      id={`distribute-button-${item.id}`}
                      type="button"
                      className="btn btn-sm btn-light w-100"
                      onClick={() => this.onButtonClick(item)}
                      disabled={!this.moreThanTwoZonesActive}
                    >
                      <FontAwesomeIcon
                        className="mr-2"
                        icon={item.icon}
                        rotation={item.iconRotation}
                      />
                      {item.label}
                    </button>
                  </div>
                )))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

AlignEntitiesButtons.propTypes = {
  game: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  single: PropTypes.bool,
};

export default observer(AlignEntitiesButtons);
