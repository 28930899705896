import lodash from 'lodash';
import {action, computed, observable, toJS, transaction} from 'mobx';
import uuid from 'uuid/v4';

import displayEditorStore from '../display/displayEditorStore';
import {cloneEntity, getActionEntity, getEntityFromSourceItem, updateEntity} from '../../display/ecs/entityHelper';
import {orderToZIndex} from '../../display/components/common/positionComponent';
import {ALIGNMENT_TARGET_CANVAS} from '../../constants/entityOptionConstants';

/**
 * The default fps value.
 * This must be 60, 30, 20, 15, 12, 10.
 * (60 / N) where N >= 1
 *
 * @const {number}
 */
const DEFAULT_FPS = 10;

/**
 * The default scale of the display-source.
 *
 * @const {number}
 */
const DEFAULT_SCALE = 1;

/**
 * Indicates this game is rendering/editing an image.
 * This will remove the timeline.
 *
 * @const {string}
 */
export const GAME_TYPE_IMAGE = 'image';

/**
 * Indicates this game is rendering/editing a video.
 * This will add in the timeline.
 *
 * @const {string}
 */
export const GAME_TYPE_VIDEO = 'video';

/**
 * The game store.
 */
export class GameStore {
  /**
   * Prevents this store from logging in the mobx logger.
   *
   * @type {{enabled: boolean}}
   */
  static mobxLoggerConfig = {
    enabled: false
  };

  /**
   * @constructor
   * @param {{type: string, endTime: number, fps: number, resolution: {width: number, height: number}}} gameConfig
   */
  constructor(gameConfig) {
    transaction(() => {
      this.type = gameConfig.type || GAME_TYPE_VIDEO;
      this.setFps(gameConfig.fps || DEFAULT_FPS);
      this.scale = gameConfig.scale || DEFAULT_SCALE;

      if (gameConfig.endTime !== undefined) {
        this.endTime = parseInt(gameConfig.endTime, 10) || 0;

        if (this.endTime > 0) {
          this.type = GAME_TYPE_VIDEO;
        } else {
          this.type = GAME_TYPE_IMAGE;
        }
      }

      if (gameConfig.screenshotTimes && Array.isArray(gameConfig.screenshotTimes)) {
        this.screenshotTimes = gameConfig.screenshotTimes;
      }

      if (gameConfig.resolution) {
        if (gameConfig.resolution.width) {
          this.resolution.width = gameConfig.resolution.width;
          this.contentResolution.width = gameConfig.resolution.width;
        }
        if (gameConfig.resolution.height) {
          this.resolution.height = gameConfig.resolution.height;
          this.contentResolution.height = gameConfig.resolution.height;
        }
      }
    });
  }

  /**
   * A unique id for this game instance.
   *
   * @type {string}
   */
  id = uuid();

  /**
   * The game type, usually image or video.
   *
   * @type {string}
   */
  type = 'video';

  /**
   * The frames per second for the game.
   *
   * @type {number}
   */
  fps = DEFAULT_FPS;

  /**
   * The display source scale.
   * This should only be used in the render process.
   *
   * @type {number}
   */
  scale = DEFAULT_SCALE;

  /**
   * Whether or not the game is playing during a render process.
   *
   * @type {boolean}
   */
  isRender = false;

  /**
   * Persistent storage for last used alignment target
   *
   * @type {string]}
   */
  alignmentTarget = ALIGNMENT_TARGET_CANVAS;

  /**
   * The time (in milliseconds) when the game will finish.
   *
   * @type {number}
   */
  @observable endTime = null;

  /**
   * The time (in milliseconds) when the screenshots should be taken.
   * If only one item is in the array, it will take 1 screen shot. If multiple items are in the array,
   * it will take an animation screen shot.
   *
   * @type {?Array<number>}
   */
  @observable screenshotTimes = null;

  /**
   * The resolution (width and height) of the game.
   *
   * @type {{width: number, height: number}}
   */
  @observable resolution = {width: 0, height: 0};

  /**
   * The content resolution (width and height) of the game.
   *
   * @type {{width: number, height: number}}
   */
  @observable contentResolution = {width: 0, height: 0};

  /**
   * The list of systems.
   *
   * @type {Array.<{}>}
   */
  systems = [];

  /**
   * The list of actions.
   *
   * @type {Array.<{}>}
   */
  actions = [];

  /**
   * The list of entities.
   *
   * @type {ObservableArray}
   */
  @observable entities = [];

  /**
   * The game history.
   *
   * @type {?GameHistoryStore}
   */
  history = null;

  /**
   * The game timer.
   *
   * @type {?GameTimerStore}
   */
  @observable timer = null;

  /**
   * Whether or not the game is in compose mode.
   * This means that only variables can be changed.
   *
   * @type {boolean}
   */
  @observable composeMode = false;

  /**
   * Whether an entity is actively being dragged
   *
   * @type {boolean}
   */
  @observable isDragging = false;

  /**
   * Sets whether or not the game is running in the render process.
   *
   * @param {boolean} isRender
   */
  setIsRender(isRender) {
    this.isRender = Boolean(isRender);
  }

  /**
   * Gets whether or not the game should have a time line.
   *
   * @returns {boolean}
   */
  hasTimeLine() {
    return true;
  }

  /**
   * Gets an entity by the entity id.
   *
   * @param {string} entityId
   * @returns {{id: string}}
   */
  getEntity(entityId) {
    return lodash.find(this.entities, (entity) => {
      return (entity.get('id') === String(entityId));
    });
  }

  /**
   * Gets the first active entity.
   *
   * @returns {{id: string}}
   */
  @computed get activeEntity() {
    return lodash.find(this.entities, (entity) => {
      return (entity.has('interaction') && entity.get('interaction').isActive);
    });
  }

  /**
   * Gets all the active entities.
   *
   * @returns {{id: string}}
   */
  @computed get allActiveEntities() {
    return lodash.filter(this.entities, (entity) => {
      return (entity.has('interaction') && entity.get('interaction').isActive);
    });
  }

  /**
   * Adds an entity to the game.
   *
   * @param {{}} sourceEntity
   * @param {{}=} variables
   * @returns {ObservableMap}
   */
  @action addSourceEntity(sourceEntity, variables) {
    const newOrder = this.entities.length;
    const entity = getEntityFromSourceItem(sourceEntity, newOrder, this, variables);

    return this.addEntity(entity, true);
  }

  /**
   * Adds an entity to the game.
   *
   * @param {{id: string}} entity
   * @param {boolean=} makeObservable
   * @param {string=} observableName
   * @returns {ObservableArray}
   */
  @action addEntity(entity, makeObservable, observableName) {
    let newEntity = entity;
    if (makeObservable) {
      const firstFeedIndex = toJS(this.entities).findIndex((item) => item.element.includes('feed'));

      // add entity to top of layers
      let newIndex = this.entities.length;

      if (entity.element === 'timer') {
        // add timer as bottom layer. The timer layer is not visual, but we want
        // to add it to the bottom so there isn't any bugs with re-ordering
        newIndex = 0;
      } else if (firstFeedIndex !== -1) {
        // if feed entity exists, make sure new entity is spliced into array before feed layer
        // this ensures feed layers are always pinned to top of layers
        // see https://projectcontent.atlassian.net/browse/PS-182
        newIndex = firstFeedIndex;
      }

      const safeName = observableName || `entity-${newIndex}__${entity.element}`;
      newEntity = observable.map(entity, safeName);

      this.entities.splice(newIndex, 0, newEntity);

      // recalulate z-indexes. This is needed when a feed layer already exists when a new layer is added.
      // Since the feed layer will be on top of the new layer, the feed layer's z-index needs to be updated.
      this.entities.forEach((item, index) => {
        const zIndex = orderToZIndex(index);

        updateEntity(item, 'position', {zIndex});
      });
    }

    return newEntity;
  }

  /**
   * Duplicates the given entity.
   *
   * @param {string|{id: string}} entityOrId
   * @returns {{id: string}}}
   */
  duplicateEntity(entityOrId) {
    let toCopyEntity = entityOrId;
    if (typeof entityOrId === 'string') {
      toCopyEntity = this.getEntity(entityOrId);
    }

    if (!toCopyEntity) {
      return null;
    }

    const newEntity = cloneEntity(toCopyEntity);
    this.addEntity(newEntity, true);

    return newEntity;
  }

  /**
   * Removes an entity from the game.
   *
   * @param {string|{id: string}} entityOrId
   */
  @action removeEntity(entityOrId) {
    let toRemoveEntity = entityOrId;
    if (typeof entityOrId === 'string') {
      toRemoveEntity = this.getEntity(entityOrId);
    }

    if (!toRemoveEntity) {
      return;
    }

    // Remove is a special observable array function.
    // @see {@url https://mobx.js.org/refguide/array.html}
    this.entities.remove(toRemoveEntity);
  }

  /**
   * Clears all entities from the game.
   */
  @action removeAllEntities() {
    // Clear is a special observable array function.
    // @see {@url https://mobx.js.org/refguide/array.html}
    this.entities.clear();
  }

  /**
   * Adds an action to the game.
   *
   * @param {{}} actionParams
   * @param {Object.<string, {}>} components
   */
  @action addAction(actionParams, components) {
    const entity = getActionEntity(actionParams, components);
    const newOrder = this.actions.length;

    this.actions.push(observable.map(entity, `action-${newOrder}`));
  }

  /**
   * Clears all actions from the game.
   */
  @action removeAllActions() {
    if (this.actions.length) {
      this.actions = [];
    }
  }

  /**
   * Gets a system by system name.
   *
   * @param {string} systemName
   * @returns {{name: string, update: function}}
   */
  getSystem(systemName) {
    return lodash.find(this.systems, {name: systemName});
  }

  /**
   * Adds a system to the game.
   *
   * @param {{}} system
   * @param {number} priority
   * @throws {Error} If priority is undefined.
   */
  @action addSystem(system, priority) {
    if (priority === undefined) {
      throw new Error('A priority is required when adding a system to the game.');
    }

    system.priority = parseInt(priority, 10);

    if (system.attach) {
      system.attach();
    }

    const newIndex = lodash.sortedIndexBy(this.systems, system, 'priority');
    this.systems.splice(newIndex, 0, system);
  }

  /**
   * Removes a system from the game.
   *
   * @param {string|{name: string}} systemOrName
   */
  @action removeSystem(systemOrName) {
    let index = -1;
    if (typeof systemOrName === 'string') {
      index = lodash.findIndex(this.systems, {name: systemOrName});
    } else {
      index = this.systems.indexOf(systemOrName);
    }

    if (index < 0) {
      return;
    }

    const system = this.systems[index];
    this.systems.splice(index, 1);

    if (system && system.detach) {
      system.detach();
    }
  }

  /**
   * Removes all systems from the game.
   */
  @action removeAllSystems() {
    this.systems.forEach(this.removeSystem);
  }

  /**
   * Adds an undo point by recording the current time and entities.
   *
   * @param {boolean=} isFromActions
   */
  @action addUndoPoint(isFromActions) {
    if (!this.history) {
      return;
    }

    if (isFromActions) {
      let skipHistory = true;
      this.actions.forEach((actionEntity) => {
        const actionComponent = actionEntity.get('action');
        if (!actionComponent.skipHistory) {
          skipHistory = false;
        }
      });

      if (skipHistory) {
        return;
      }
    }

    this.history.addPoint(this);
  }

  /**
   * Undoes the changes to the last history point.
   *
   * @returns {boolean}
   */
  @action undoToLastPoint() {
    if (!this.history) {
      return false;
    }

    this.history.startProcess(this);

    const previousPoint = this.history.popUndoPoint();
    if (!previousPoint) {
      return false;
    }

    this.resetAfterUndoRedo(previousPoint);

    return true;
  }

  /**
   * Redoes the last recorded undo point.
   *
   * @returns {boolean}
   */
  @action redoLastUndo() {
    if (!this.history) {
      return false;
    }

    const redoPoint = this.history.popRedoHistory();
    if (!redoPoint) {
      return false;
    }

    this.resetAfterUndoRedo(redoPoint);

    return true;
  }

  /**
   * Resets the game entities and time by history point.
   *
   * @param {{gameTime: number, entities: Array.<{}>, variables: {}}} historyPoint
   */
  @action resetAfterUndoRedo(historyPoint) {
    this.timer.pause();

    displayEditorStore.setAllVariables(historyPoint.variables);

    this.removeAllEntities();
    historyPoint.entities.forEach((sourceEntity) => {
      this.addSourceEntity(sourceEntity, historyPoint.variables);
    });

    const secondsToMilliseconds = 1000;
    const msPerFrame = Math.ceil(secondsToMilliseconds / this.fps);

    // Wait for the next frame to update the timer, otherwise the entities will not setup correctly.
    setTimeout(() => {
      this.timer.setTime(historyPoint.gameTime);
    }, msPerFrame);
  }

  /**
   * Sets the game history object.
   *
   * @param {GameHistoryStore} gameHistory
   */
  @action setHistory(gameHistory) {
    this.history = gameHistory;
  }

  /**
   * Sets the game timer object.
   *
   * @param {GameTimerStore} gameTimer
   */
  @action setTimer(gameTimer) {
    this.timer = gameTimer;
  }

  /**
   * Sets the fps for the game.
   *
   * @param {number} newFps
   */
  @action setFps(newFps) {
    const safeFps = parseInt(newFps, 10);

    const validFps = [60, 30, 20, 15, 12, 10]; // eslint-disable-line no-magic-numbers

    if (!safeFps) {
      throw new Error('Invalid fps given to gameTimer.setFps -- The value must a number.');
    } else if (validFps.indexOf(newFps) === -1) {
      throw new Error('Invalid fps given to gameTimer.setFps -- The value must be 60, 30, 20, 15, 12, 10.');
    }

    this.fps = safeFps;
  }

  /**
   * Sets the game resolution.
   *
   * @param {number} newWidth
   * @param {number} newHeight
   */
  @action setResolution(newWidth, newHeight) {
    this.resolution.width = newWidth;
    this.resolution.height = newHeight;
  }

  /**
   * Sets the game end time.
   *
   * @param {number} newEndTime
   */
  @action setEndTime(newEndTime) {
    this.endTime = parseInt(newEndTime, 10) || 0;

    if (this.endTime > 0) {
      this.type = GAME_TYPE_VIDEO;
    } else {
      this.type = GAME_TYPE_IMAGE;
    }
  }

  /**
   * Sets the array of screenshot times.
   *
   * @param {Array<number>} newTimes
   */
  @action setScreenshotTimes(newTimes) {
    const safeTimes = (Array.isArray(newTimes)) ? newTimes : [newTimes];
    if (!safeTimes.length) {
      return;
    }

    this.screenshotTimes = safeTimes;
  }

  /**
   * Sets whether or not the game is in compose mode.
   *
   * @param {boolean} newComposeMode
   */
  @action setComposeMode(newComposeMode) {
    this.composeMode = Boolean(newComposeMode);
  }

  /**
   * Sets whether or not an entity is being dragged.
   *
   * @param {boolean} isDragging
   */
  @action setIsDragging(isDragging) {
    this.isDragging = Boolean(isDragging);
  }

  /**
   * Sets last used alignment target.
   *
   * @param {string} alignmentTarget
   */
  @action setAlignmentTarget(alignmentTarget) {
    this.alignmentTarget = alignmentTarget;
  }

  /**
   * Updates the game by calling all the systems.
   *
   * @param {number} time
   * @param {Object} updateEvent
   */
  @action update(time, updateEvent = {}) {
    if (this.actions.length) {
      this.addUndoPoint(true);
    }

    this.systems.forEach((system) => {
      if (system.runActions) {
        system.runActions(this.actions.slice(0), this.entities, time);
      }
    });
    this.systems.forEach((system) => {
      if (system.update) {
        system.update(this.entities, time, Object.assign({timer: this.timer}, updateEvent));
      }
    });
  }
}

/**
 * Parses game config into from the source file.
 *
 * @param {{type: string, endTime: number, resolution: {height: number, width: number}}} source
 * @returns {{type: string, endTime: number, fps: number, resolution: {height: number, width: number}}}
 */
export function parseGameConfigFromSource(source) {
  if (!source.endTime) {
    if (!source.type || source.type !== GAME_TYPE_IMAGE) {
      throw new Error('Invalid source file -- An endTime is required.');
    }
  } else if (!source.resolution) {
    throw new Error('Invalid source file -- A resolution object is required.');
  } else if (!source.resolution.height || !source.resolution.width) {
    throw new Error('Invalid source file -- The resolution must have a width and height.');
  } else if (source.screenshotTimes && !Array.isArray(source.screenshotTimes)) {
    throw new Error('Invalid source file -- The screenshot times must be an array of numbers.');
  }

  return {
    type: source.type || null,
    endTime: source.endTime || 0,
    screenshotTimes: source.screenshotTimes || null,
    fps: DEFAULT_FPS,
    resolution: source.resolution,
    scale: source.scale || DEFAULT_SCALE,
  };
}

// Tells the system not to use this as an injectable store.
export const doNotInject = true;
