import React from 'react';
import {observable, action} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

import inject from '../../../../hoc/injectHoc';

import OnboardStepper from '../onboardStepper/OnboardStepper';
import PricingTabs from '../../../../../components/common/pricingTabs/PricingTabs';
import OnboardUserSigns from '../onboardUserSigns/OnboardUserSigns';
import PlanOrderDetails from '../orderDetails/PlanOrderDetails';
import AlaCarteOrderDetails from '../orderDetails/AlaCarteOrderDetails';
import WaveGraphic from '../../../../../components/common/waveGraphic/WaveGraphic';

import './onboardingExperiences.scss';

const CHECKOUT_SECTION_KEY = 2;
const ONBOARDING_FLOW_SECTIONS = [
  {
    key: 1,
    label: 'Plans',
  },
  {
    key: 2,
    label: 'Sign Information'
  },
];

/**
 * The StandardOnboardingExperience component.
 */
export class StandardOnboardingExperience extends React.Component {
  @observable currentSection = 1;

  /**
   * Changes the currently displayed section
   *
   * @param {string} section
   * @param {boolean} canProgress
   */
  @action changeSection = (section, canProgress) => {
    const maxSectionCount = ONBOARDING_FLOW_SECTIONS.length + 1;
    if (section < maxSectionCount && canProgress) {
      this.currentSection = section;
    }

    if (!canProgress && section < this.currentSection) {
      this.currentSection = section;
    }
  }

  /**
   * Purchase an a la carte credit
   *
   * @param {{
   *  download: number,
   *  request: number
   * }} alaCarteCreditsParam
   */
  @action onPurchaseCredits = (alaCarteCreditsParam) => {
    const {setAlaCarteCredits} = this.props;
    setAlaCarteCredits(alaCarteCreditsParam);

    this.changeSection(CHECKOUT_SECTION_KEY, true);
  }

  /**
   * When paid plan is selected.
   *
   * @param {{}} selectedPlan
   */
  onPlanClick = (selectedPlan) => {
    const {onPlanSelect, currentPlan} = this.props;
    if (selectedPlan.id !== currentPlan.id) {
      onPlanSelect(selectedPlan);
      this.changeSection(CHECKOUT_SECTION_KEY, true);
    }
  }

  /**
   * Renders the current section of the onboarding process
   * @param {number} currentSection
   *
   * @returns {{}}
   */
  renderSection() {
    const {billingType, currentPlan, user, userSigns, checkoutWithChargebee, isLoadingChargebeeCheckout, onChangeBillingType, alaCarteCredits} = this.props;
    const SECTION_DICT = {
      1: <PricingTabs
        selectPlanId={currentPlan.id}
        onPlanSelect={this.onPlanClick}
        onPurchaseCredits={this.onPurchaseCredits}
        user={user}
        tabSelected={this.startingAlaCarteCredit ? 'credits' : 'subscription'}
      />,
      2: <div className="row">
        <OnboardUserSigns />
        {(currentPlan.id) && (<PlanOrderDetails
          billingType={billingType}
          buttonText="Confirm Purchase"
          isLoading={isLoadingChargebeeCheckout}
          plan={currentPlan}
          onChangeBillingType={onChangeBillingType}
          userSigns={userSigns}
          onSubmit={checkoutWithChargebee}
        />)}

        {(!currentPlan.id && alaCarteCredits) && (
          <AlaCarteOrderDetails
            alaCarteCredits={alaCarteCredits}
            buttonText="Confirm Purchase"
            isLoading={isLoadingChargebeeCheckout}
            onSubmit={checkoutWithChargebee}
          />
        )}
      </div>
    };
    return (
      <div className="container px-0">
        <div className="onboarding-content">
          {SECTION_DICT[this.currentSection]}
          <WaveGraphic
            waveGraphicClass="onboarding"
          />
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    return (
      <>
        <OnboardStepper
          steps={ONBOARDING_FLOW_SECTIONS}
          currentStep={this.currentSection}
          changeStep={this.changeSection}
        />
          {this.renderSection()}
      </>
    );
  }
}

StandardOnboardingExperience.propTypes = {
  billingType: PropTypes.string.isRequired,
  checkoutWithChargebee: PropTypes.func.isRequired,
  currentPlan: PropTypes.object.isRequired,
  isLoadingChargebeeCheckout: PropTypes.bool.isRequired,
  onChangeBillingType: PropTypes.func.isRequired,
  onPlanSelect: PropTypes.func.isRequired,
  setAlaCarteCredits: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,

  alaCarteCredits: PropTypes.object,
  userSigns: PropTypes.object,
};

StandardOnboardingExperience.wrappedComponent = {};
StandardOnboardingExperience.wrappedComponent.propTypes = {
};

export default inject(StandardOnboardingExperience)(
  observer(StandardOnboardingExperience)
);
