/**
 * The action position and size change component.
 *
 * @param {boolean} isResize
 * @param {?{y: number, x: number}} positionDelta
 * @param {?{width: number, height: number}} sizeDelta
 * @param {?number} newRotate
 * @param {boolean} moveCrop
 * @param {boolean} shouldSnap
 * @returns {{actionPosition: {positionDelta: ?{}, sizeDelta: ?{}, newRotate: ?number}}}
 */
export function actionPositionComponent(isResize, positionDelta, sizeDelta, newRotate, moveCrop, shouldSnap) {
  return {
    actionPosition: {
      isResize: Boolean(isResize),
      positionDelta,
      sizeDelta,
      newRotate,
      moveCrop: Boolean(moveCrop),
      shouldSnap: Boolean(shouldSnap),
    },
  };
}
