import React from 'react';
import {observable, action, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import lodash from 'lodash';
import PropTypes from 'prop-types';

import inject from '../../../../hoc/injectHoc';
import {getAssetUrl} from '../../../../../utils/assetHelper';

import OnboardStepper from '../onboardStepper/OnboardStepper';
import LoadingIcon from '../../../../common/loadingIcon/LoadingIcon';
import SignManufacturerBanner from '../../components/signManufacturerBanner/SignManufacturerBanner';
import SubscriptionPlanTable from '../../../../../components/common/subscriptionPlanTable/SubscriptionPlanTable';
import PlanOrderDetails from '../orderDetails/PlanOrderDetails';
import OnboardUserSigns from '../../components/onboardUserSigns/OnboardUserSigns';
import NextManufacturerPromo from './components/NextManufacturerPromo';
import {centsToDollars} from '../../../../../utils/mathHelper';
import WaveGraphic from '../../../../../components/common/waveGraphic/WaveGraphic';
import {PAYMENT_PLAN_MONTHLY} from '../../../../../constants/subscriptionPlanConstants';

import './onboardingExperiences.scss';

/**
 * The URL for the welcome video
 * @type {string}
 */
const welcomeVideoUrl = getAssetUrl('welcome-promo-video.mp4');

// Onboarding experience for users that have paid for a sign with a partner sign manufacturer. NextLED at this point in time.
// 1. Welcome video
// 2. What's Included
// 3. Paid Plan options
// 4. Sign Onboarding / Checkout
const CHECKOUT_SECTION_KEY = 4;

const ONBOARDING_FLOW_SECTIONS = [
  {
    key: 1,
    label: 'Welcome',
  },
  {
    key: 2,
    label: "What's Included",
  },
  {
    key: 3,
    label: 'Plans',
  },
  {
    key: 4,
    label: 'Sign Information'
  }
];

/**
 * The ManufacturerOnboardingExperience component.
 */
class ManufacturerOnboardingExperience extends React.Component {
  /**
   * Current section displayed in the onboarding process
   *
   * @type {number}
   */
  @observable currentSection = 1;

  @observable defaultPlan = null;

  /**
   * Triggered when the component first mounts to the page.
   */
  componentDidMount() {
    const {apiPlanGetAllStore, onPlanSelect} = this.props;

    apiPlanGetAllStore.refresh();
    apiPlanGetAllStore.getPromise().then((plans) => {
      plans.forEach((plan) => {
        const formattedPlan = {
          ...plan,
          price: centsToDollars(plan.price),
          priceCents: plan.price,
          displayPrice: centsToDollars(plan.displayPrice),
          displayPriceCents: plan.displayPrice
        };
        if (plan.chargebeePlanId === 'next') {
          onPlanSelect(formattedPlan);
          runInAction('Set the default plan.', () => {
            this.defaultPlan = formattedPlan;
          });
        }
      });
    });
  }

  /**
   * Changes the currently displayed section
   *
   * @param {string} section
   * @param {boolean} canProgress
   */
  @action changeSection = (section, canProgress) => {
    const maxSectionCount = ONBOARDING_FLOW_SECTIONS.length + 1;
    if (section < maxSectionCount && canProgress) {
      this.currentSection = section;
    }

    if (!canProgress && section < this.currentSection) {
      this.currentSection = section;
    }
  }

  /**
   * When paid plan is selected.
   *
   * @param {{}} selectedPlan
   */
  onPlanClick = (selectedPlan) => {
    const {onPlanSelect, currentPlan} = this.props;
    if (selectedPlan.id !== currentPlan.id) {
      this.changeSection(CHECKOUT_SECTION_KEY, true);
      onPlanSelect(selectedPlan);
    }
  }

  /**
   * Skip paid plan selection
   */
  onSkipPaidPlan = () => {
    const {onPlanSelect, onChangeBillingType} = this.props;
    this.changeSection(CHECKOUT_SECTION_KEY, true);

    // force plan to be monthly
    onChangeBillingType(PAYMENT_PLAN_MONTHLY);
    onPlanSelect(this.defaultPlan);
  }

  /**
   * Renders the current section of the onboarding process
   * @param {number} currentSection
   *
   * @returns {{}}
   */
  renderSection() {
    const {billingType, currentPlan, user, userSigns, checkoutWithChargebee, isLoadingChargebeeCheckout, onChangeBillingType} = this.props;
    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');
    const canManageSigns = currentPlan ? currentPlan.canManageSigns : true;

    const NextButton = (<div className="d-flex justify-content-end mt-3">
      <button
        type="button"
        className="btn btn-primary text-light"
        onClick={() => this.changeSection(this.currentSection + 1, true)}
      >
        Next
      </button>
    </div>);
    const SECTION_DICT = {
      1: <div>
        <video className="welcome-video" autoPlay={true} muted={true} controls>
          <source src={welcomeVideoUrl} type="video/mp4" />
          Video not working
        </video>
        {NextButton}
      </div>,
      2: <div>
        {this.defaultPlan && <NextManufacturerPromo highlightedPlan={this.defaultPlan} />}
        {NextButton}
      </div>,
      3: <div className="plan-table">
        <SignManufacturerBanner signManufacturer={companyManufacturerSignupType} />
        <SubscriptionPlanTable
          onPlanSelect={this.onPlanClick}
          selectPlanId={currentPlan.id}
          user={user}
          fromOnboarding={true}
        />
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-primary text-light"
            onClick={this.onSkipPaidPlan}
          >
            Skip
          </button>
        </div>
      </div>,
      4: <div className="row">
        <OnboardUserSigns canManageSigns={canManageSigns} />
        <PlanOrderDetails
          billingType={billingType}
          buttonText={currentPlan.displayPrice > 0 ? 'Confirm Purchase' : 'Confirm'}
          isLoading={isLoadingChargebeeCheckout}
          plan={currentPlan}
          onChangeBillingType={onChangeBillingType}
          userSigns={userSigns}
          onSubmit={checkoutWithChargebee}
        />
      </div>,
    };

    return (
      <div className="container px-0">
        <div className="onboarding-content">
          {SECTION_DICT[this.currentSection]}
          <WaveGraphic
            waveGraphicClass="onboarding"
          />
        </div>
      </div>
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiPlanGetAllStore} = this.props;

    return apiPlanGetAllStore.case({
      pre: () => (<LoadingIcon />),
      pending: () => (<LoadingIcon />),
      rejected: () => (
        <div className="subscription-plan-table">
          <div className="alert alert-warning">
            The plan data could not be loaded. Please refresh the page to try again.
          </div>
        </div>
      ),
      fulfilled: () => (
        <>
          <OnboardStepper
            steps={ONBOARDING_FLOW_SECTIONS}
            currentStep={this.currentSection}
            changeStep={this.changeSection}
          />
            {this.renderSection()}
        </>
      ),
    });
  }
}

ManufacturerOnboardingExperience.propTypes = {
  billingType: PropTypes.string.isRequired,
  checkoutWithChargebee: PropTypes.func.isRequired,
  currentPlan: PropTypes.object.isRequired,
  isLoadingChargebeeCheckout: PropTypes.bool.isRequired,
  onChangeBillingType: PropTypes.func.isRequired,
  onPlanSelect: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,

  apiPlanGetAllStore: MobxPropTypes.observableObject,
  userSigns: PropTypes.object,
};

ManufacturerOnboardingExperience.wrappedComponent = {};
ManufacturerOnboardingExperience.wrappedComponent.propTypes = {
  apiPlanGetAllStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ManufacturerOnboardingExperience)(
  observer(ManufacturerOnboardingExperience)
);
