
import React from 'react';
import {action, observable} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import lodash from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLock, faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';

import SizeEntityButtons from './component/sizeEntityButtons/SizeEntityButtons';
import AlignEntitiesButtons from '../editMultiple/components/alignEntitiesButtons/AlignEntitiesButtons';

import './editPositionControls.scss';

/**
 * The EditPositionControls component.
 */
export class EditPositionControls extends React.Component {
  /**
   * Whether or not the position control group is open.
   *
   * @type {boolean}
   */
  @observable isControlGroupOpen = false;

  @action onToggleControlGroup = () => {
    this.isControlGroupOpen = !this.isControlGroupOpen;
  }

  /**
   * Renders the Position Controls.
   *
   * @returns {{}}
   */
  renderPositionControls() {
    const {
      /** @type {ObservableMap} */ entity,
      /** @type {GameStore} */ game,
    } = this.props;

    if (!this.isControlGroupOpen) {
      return null;
    }

    let isLocked = false;
    if (entity.has('locked')) {
      const entityLock = entity.get('locked');
      if (lodash.includes(entityLock, 'position')) {
        // If the entity has position locked, then do not allow them to adjust anything.
        isLocked = true;
      }
    }

    return ((isLocked) ? (
      <div className="can-not-edit">
        <span className="locked-text">
          <FontAwesomeIcon
            className="mr-2"
            icon={faLock}
          />
          <span>Position Locked</span>
        </span>
      </div>
    ) : (
      <div className="group-controls">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="position-alignment-entities">Align</label>
              <AlignEntitiesButtons
                id="position-alignment-entities"
                game={game}
                single={true}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="position-alignment-entities">Size</label>
              <SizeEntityButtons
                id="position-size-entities"
                game={game}
                single={true}
              />
            </div>
          </div>
        </div>
      </div>
    )
    );
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {GameStore} */ game,
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    return (
      <div className="edit-position-controls control-group">
        <div className="group-header">
          <span className="group-header-label">Positioning</span>
          <button
            type="button"
            className="btn"
            onClick={this.onToggleControlGroup}
          >
            <FontAwesomeIcon icon={this.isControlGroupOpen ? faChevronUp : faChevronDown} />
          </button>
        </div>
        {this.renderPositionControls()}
      </div>
    );
  }
}

EditPositionControls.propTypes = {
  entity: MobxPropTypes.observableMap.isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditPositionControls);
