import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';

import {ALIGNMENTS} from '../components/common/positionComponent';
import {adjustParamsDefaults, clearTransitionCache, getBoundaries, updateEntity} from '../ecs/entityHelper';
import {isInRange} from '../../utils/mathHelper';
import {getIsEntityTransitioning} from '../../utils/dragDropHelper';

const SNAPLINE_TOLERANCE_IN_PIXELS = 10;

/**
 * The name of the system.
 * @const {string}
 */
export const POSITIONING_SYSTEM = 'positioningSystem';

/**
 * Gets a new instance of the position system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function positioningSystem(game) {
  const xSnapMap = {};
  const ySnapMap = {};

  /**
   * Called when the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    actions.forEach((actionEntity) => {
      // First check for required components.
      if (!actionEntity.has('actionPosition')) {
        return;
      }

      const entityIds = actionEntity.get('action').entityId
        ? [actionEntity.get('action').entityId]
        : actionEntity.get('action').entityIds;

      const safeEntities = entities.filter((item) => entityIds.includes(item.get('id')));

      if (safeEntities.length === 0) {
        return;
      }

      const actionPosition = actionEntity.get('actionPosition');
      const moveCrop = Boolean(actionPosition.moveCrop);
      const shouldSnap = Boolean(actionPosition.shouldSnap);

      const {
        entityPosition: groupPosition,
        entitySize: groupSize,
      } = getEntityBoundaries(safeEntities, game.resolution);

      const otherEntities = game.entities.filter((item) => (
        !!item.get('position')
        && !!item.get('size')
        && !safeEntities.some((innerItem) => innerItem.get('id') === item.get('id'))
      ));

      const snapKey = safeEntities.reduce((acc, item, index) => {
        return index === 0
          ? item.get('id')
          : `${acc}_${item.get('id')}`;
      }, 'sk_');

      const snapPosition = shouldSnap
        ? checkSnap(
          groupPosition,
          groupSize,
          actionEntity,
          otherEntities,
          snapKey,
        )
        : null;

      // TODO - Here we are modifying the original positionDelta to update how the entity should be positioned
      // Ideally we could pass in new coordinates to checkBoundaries to avoid
      // mutating the original action. This was done this way to avoid breaking those check functions
      if (snapPosition && snapPosition.dx !== null) {
        actionPosition.positionDelta = {
          ...actionPosition.positionDelta,
          x: snapPosition.dx,
        };
        actionEntity.set('actionPosition', actionPosition);
      }
      if (snapPosition && snapPosition.dy !== null) {
        actionPosition.positionDelta = {
          ...actionPosition.positionDelta,
          y: snapPosition.dy,
        };
        actionEntity.set('actionPosition', actionPosition);
      }

      const {
        safePosition: newGroupPosition,
        safeSize: newGroupSize,
      } = checkBoundariesV2(
        groupPosition,
        groupSize,
        actionEntity,
      );

      // calculate how much position has changed
      const dx = newGroupPosition.x - groupPosition.x;
      const dy = newGroupPosition.y - groupPosition.y;

      safeEntities
        .filter((entity) => !getIsEntityTransitioning(entity, game.timer.elapsedTime, ['position']))
        .forEach((entity) => {
          runInAction('positioningSystemUpdateEntity', () => {
            const {
              safeImage,
              safeCrop
            } = checkImageUpdates(groupPosition, newGroupPosition, groupSize, newGroupSize, entity, moveCrop);

            if (newGroupPosition) {
              const entitySafePosition = lodash.merge(
                lodash.cloneDeep(entity.get('position')),
                {
                  x: entity.get('position').x + dx,
                  y: entity.get('position').y + dy,
                },
              );

              // only allow rotation when 1 elemenet is being positioned
              if (safeEntities.length === 1) {
                entitySafePosition.rotate = newGroupPosition.rotate;
              }

              updateEntity(entity, 'position', adjustParamsDefaults(entitySafePosition, game.resolution));
            }
            if (newGroupSize) {
              const widthRatio = entity.get('size').width / groupSize.width;
              const heightRatio = entity.get('size').height / groupSize.height;

              const entitySafeSize = lodash.merge(
                lodash.cloneDeep(entity.get('size')),
                {
                  width: newGroupSize.width * widthRatio,
                  height: newGroupSize.height * heightRatio,
                },
              );

              updateEntity(entity, 'size', adjustParamsDefaults(entitySafeSize, game.resolution));
            }
            if (safeImage) {
              updateEntity(entity, 'image', adjustParamsDefaults(safeImage, game.resolution));
            }
            if (safeCrop) {
              updateEntity(entity, 'crop', adjustParamsDefaults(safeCrop, game.resolution));
            }

            clearTransitionCache(entity);
          });
        });
    });
  }

  /**
   * Gets the deltas.
   *
   * @param {{}} actionEntity
   * @param {{}} entityPosition
   * @returns {{positionDelta: {}, sizeDelta: {}, newRotate: ?number}}
   */
  function getDeltasV2(actionEntity, entityPosition) {
    const {isResize, positionDelta, sizeDelta, newRotate} = actionEntity.get('actionPosition');

    const safePositionDelta = (positionDelta) ? toJS(positionDelta) : null;

    if (safePositionDelta) {
      if (entityPosition.alignment.y === ALIGNMENTS.y.bottom) {
        safePositionDelta.y = 0 - safePositionDelta.y;
      }
      if (entityPosition.alignment.x === ALIGNMENTS.x.right) {
        safePositionDelta.x = 0 - safePositionDelta.x;
      }
    }

    return {
      isResize,
      positionDelta: safePositionDelta,
      sizeDelta: (sizeDelta) ? toJS(sizeDelta) : null,
      newRotate: (newRotate || newRotate === 0) ? newRotate : null,
    };
  }

  /**
   * Checks to see if the size or position changes takes the item over the boundary.
   *
   * @param {{}} entityPosition
   * @param {{}} entitySize
   * @param {ObservableMap} actionEntity
   * @returns {{safePosition: ?{y: number, x: number}, safeSize: ?{width: number, height: number}}}
   */
  function checkBoundariesV2(entityPosition, entitySize, actionEntity) {
    const {isResize, positionDelta, sizeDelta} = getDeltasV2(actionEntity, entityPosition);
    const {safePosition, safeSize} = getNewPositionAndSizeV2(actionEntity, entityPosition, entitySize);

    const gameResolution = game.resolution;
    const sizePadding = 10;

    let truncateHeight = 0;
    let truncateWidth = 0;

    if (getDeltaHasChanges(positionDelta)) {
      const boundaryChanges = checkCroppingBoundaries(
        safeSize,
        safePosition,
        safePosition.alignment,
        gameResolution,
        sizePadding
      );

      safePosition.y = boundaryChanges.y;
      safePosition.x = boundaryChanges.x;

      if (isResize) {
        truncateHeight = boundaryChanges.truncateHeight;
        truncateWidth = boundaryChanges.truncateWidth;
      }
    } else if (getDeltaHasChanges(sizeDelta)) {
      // Since changing size to the left or up requires a position change too, this section is only for right and down.

      let limitTop = sizePadding;
      let limitLeft = sizePadding;
      let limitBottom = gameResolution.height - sizePadding;
      let limitRight = gameResolution.width - sizePadding;

      const alignment = safePosition.alignment;
      if (alignment.y === ALIGNMENTS.y.middle) {
        const adjustForMiddle = ((gameResolution.height - safeSize.height) / 2);
        limitTop -= adjustForMiddle;
        limitBottom -= adjustForMiddle;
      }
      if (alignment.x === ALIGNMENTS.x.center) {
        const adjustForCenter = ((gameResolution.width - safeSize.width) / 2);
        limitLeft -= adjustForCenter;
        limitRight -= adjustForCenter;
      }

      const fullWidth = safePosition.x + safeSize.width;
      const fullHeight = safePosition.y + safeSize.height;
      if (fullWidth < limitLeft) {
        // This prevents the entity's right side from leaving the left side of the display.
        truncateWidth = fullWidth - limitLeft;
      } else if (safeSize.width < sizePadding) {
        // This prevents the entity's width from dropping below the sizePadding when resizing from the right.
        truncateWidth = safeSize.width - sizePadding;
      } else if (fullWidth > limitRight) {
        // This will prevent the entity's edge from going off the right side of the display.
        // truncateWidth = fullWidth - limitRight;
      }

      if (fullHeight < limitTop) {
        // This prevents the entity's bottom from leaving the top of the display.
        truncateHeight = fullHeight - limitTop;
      } else if (safeSize.height < sizePadding) {
        // This prevents the entity's height from dropping below the sizePadding when resizing from the bottom.
        truncateHeight = safeSize.height - sizePadding;
      } else if (fullHeight > limitBottom) {
        // This will prevent the entity's edge from going off the bottom of the display.
        // truncateHeight = fullHeight - limitBottom;
      }
    }

    if (safeSize && (truncateWidth || truncateHeight)) {
      safeSize.width -= truncateWidth;
      safeSize.height -= truncateHeight;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Get boundaries based on given entities
   *
   * @param {Array<ObservableMap>} safeEntities
   * @param {{}} resolution
   * @returns {{entityPosition: {}, entitySize: {}}}
   */
  function getEntityBoundaries(safeEntities, resolution) {
    const xBoundaries = getBoundaries(safeEntities, 'x', 'width', resolution);
    const yBoundaries = getBoundaries(safeEntities, 'y', 'height', resolution);

    const entityPosition = {
      x: xBoundaries.position,
      y: yBoundaries.position,
      alignment: {
        x: safeEntities.length === 1
          ? safeEntities[0].get('position').alignment.x
          : ALIGNMENTS.x.left,
        y: safeEntities.length === 1
          ? safeEntities[0].get('position').alignment.y
          : ALIGNMENTS.y.top,
      },
      rotate: safeEntities.length === 1
        ? safeEntities[0].get('position').rotate
        : 0,
    };
    const entitySize = {
      width: xBoundaries.size,
      height: yBoundaries.size,
    };

    return {
      entityPosition,
      entitySize,
    };
  }

  /**
   * Checks to see if entity needs to be positioned to snap to nearby element.
   *
   * @param {{}} entityPosition
   * @param {{}} entitySize
   * @param {ObservableMap} actionEntity
   * @param {Array<ObservableMap>} otherEntities
   * @param {string} snapKey
   * @returns {{x: number, y: number}|null}
   */
  function checkSnap(
    entityPosition,
    entitySize,
    actionEntity,
    otherEntities,
    snapKey,
  ) {
    const snapPosition = {
      x: null, // x position entity is snapping to
      y: null, // y position entity is snapping to
      dx: null, // change in x to get to snapping position
      dy: null, // change in y to get to snapping position
    };

    const {positionDelta} = getDeltasV2(actionEntity, entityPosition);

    if (!positionDelta) {
      return snapPosition;
    }

    const {safePosition, safeSize} = getNewPositionAndSizeV2(actionEntity, entityPosition, entitySize);

    const {
      x: activeEntityLeft,
      y: activeEntityTop,
    } = safePosition;
    const {
      width: activeEntityWidth,
      height: activeEntityHeight,
    } = safeSize;

    const activeEntityCenterX = activeEntityLeft + (activeEntityWidth / 2);
    const activeEntityCenterY = activeEntityTop + (activeEntityHeight / 2);

    const activeEntityRight = activeEntityLeft + activeEntityWidth;
    const activeEntityBottom = activeEntityTop + activeEntityHeight;

    const {
      snapX,
      snapY,
    } = otherEntities.reduce((acc, otherEntity) => {
      const {
        width: otherEntityWidth,
        height: otherEntityHeight,
      } = otherEntity.get('size');
      const {
        x: otherEntityLeft,
        y: otherEntityTop,
      }  = otherEntity.get('position');
      const otherEntityCenterX = otherEntityLeft + (otherEntityWidth / 2);
      const otherEntityCenterY = otherEntityTop + (otherEntityHeight / 2);
      const otherEntityRight = otherEntityLeft + otherEntityWidth;
      const otherEntityBottom = otherEntityTop + otherEntityHeight;

      const snapComparisonsX = [
        // check if active left is aligned to other left
        {
          valueToCheck: activeEntityLeft,
          rangeMedian: otherEntityLeft,
          valueIfWithinRange: otherEntityLeft,
        },

        // check if active left is aligned to other center
        {
          valueToCheck: activeEntityLeft,
          rangeMedian: otherEntityCenterX,
          valueIfWithinRange: otherEntityCenterX,
        },

        // check if active left is aligned to other right
        {
          valueToCheck: activeEntityLeft,
          rangeMedian: otherEntityRight,
          valueIfWithinRange: otherEntityRight,
        },

        // check if active center is aligned to other left
        {
          valueToCheck: activeEntityCenterX,
          rangeMedian: otherEntityLeft,
          valueIfWithinRange: otherEntityLeft - (activeEntityWidth / 2),
        },

        // check if active center is aligned to other center
        {
          valueToCheck: activeEntityCenterX,
          rangeMedian: otherEntityCenterX,
          valueIfWithinRange: otherEntityCenterX - (activeEntityWidth / 2),
        },

        // check if active center is aligned to other right
        {
          valueToCheck: activeEntityCenterX,
          rangeMedian: otherEntityRight,
          valueIfWithinRange: otherEntityRight - (activeEntityWidth / 2),
        },

        // check if active right is aligned to other left
        {
          valueToCheck: activeEntityRight,
          rangeMedian: otherEntityLeft,
          valueIfWithinRange: otherEntityLeft - activeEntityWidth,
        },

        // check if active right is aligned to other center
        {
          valueToCheck: activeEntityRight,
          rangeMedian: otherEntityCenterX,
          valueIfWithinRange: otherEntityCenterX - activeEntityWidth,
        },

        // check if active right is aligned to other right
        {
          valueToCheck: activeEntityRight,
          rangeMedian: otherEntityRight,
          valueIfWithinRange: otherEntityRight - activeEntityWidth,
        },
      ];

      snapComparisonsX.forEach(({valueToCheck, rangeMedian, valueIfWithinRange}) => {
        if (isInRange(valueToCheck, rangeMedian - SNAPLINE_TOLERANCE_IN_PIXELS, rangeMedian + SNAPLINE_TOLERANCE_IN_PIXELS)) {
          acc.snapX.push(valueIfWithinRange);
        }
      });

      const snapComparisonsY = [
        // check if active top is aligned to other top
        {
          valueToCheck: activeEntityTop,
          rangeMedian: otherEntityTop,
          valueIfWithinRange: otherEntityTop,
        },

        // check if active top is aligned to other center
        {
          valueToCheck: activeEntityTop,
          rangeMedian: otherEntityCenterY,
          valueIfWithinRange: otherEntityCenterY,
        },

        // check if active top is aligned to other bottom
        {
          valueToCheck: activeEntityTop,
          rangeMedian: otherEntityBottom,
          valueIfWithinRange: otherEntityBottom,
        },

        // check if active center is aligned to other top
        {
          valueToCheck: activeEntityCenterY,
          rangeMedian: otherEntityTop,
          valueIfWithinRange: otherEntityTop - (activeEntityHeight / 2),
        },

        // check if active center is aligned to other center
        {
          valueToCheck: activeEntityCenterY,
          rangeMedian: otherEntityCenterY,
          valueIfWithinRange: otherEntityCenterY - (activeEntityHeight / 2),
        },

        // check if active center is aligned to other bottom
        {
          valueToCheck: activeEntityCenterY,
          rangeMedian: otherEntityBottom,
          valueIfWithinRange: otherEntityBottom - (activeEntityHeight / 2),
        },

        // check if active bottom is aligned to other top
        {
          valueToCheck: activeEntityBottom,
          rangeMedian: otherEntityTop,
          valueIfWithinRange: otherEntityTop - activeEntityHeight,
        },

        // check if active bottom is aligned to other center
        {
          valueToCheck: activeEntityBottom,
          rangeMedian: otherEntityCenterY,
          valueIfWithinRange: otherEntityCenterY - activeEntityHeight,
        },

        // check if active bottom is aligned to other bottom
        {
          valueToCheck: activeEntityBottom,
          rangeMedian: otherEntityBottom,
          valueIfWithinRange: otherEntityBottom - activeEntityHeight,
        },
      ];

      snapComparisonsY.forEach(({valueToCheck, rangeMedian, valueIfWithinRange}) => {
        if (isInRange(valueToCheck, rangeMedian - SNAPLINE_TOLERANCE_IN_PIXELS, rangeMedian + SNAPLINE_TOLERANCE_IN_PIXELS)) {
          acc.snapY.push(valueIfWithinRange);
        }
      });

      return acc;
    }, {
      snapX: [],
      snapY: [],
    });

    const sortedSnapX = lodash.sortBy(snapX);
    if (sortedSnapX.length > 0) {
      snapPosition.x = sortedSnapX[0];

      // TODO - add starting clientX so this properly resets when new drag is begun.
      if (typeof xSnapMap[snapKey] === 'undefined') {
        xSnapMap[snapKey] = {
          originX: entityPosition.x + positionDelta.x,
          snapX: snapPosition.x,
          dx: 0,
        };
      } else {
        xSnapMap[snapKey].dx += positionDelta.x;
      }

      // set new default x position to snap position
      snapPosition.dx = snapPosition.x - entityPosition.x;

      const snapEntry = xSnapMap[snapKey];

      // if entity is dragged outside snapzone, update x position of entity to match up with cursor position
      //   dx = total x entity has moved since it has been snapped
      //   originX = the coordinate at which the element would have been before being snapped
      //   snapX = the coordinate at which the element snapped to
      // For example: say the entity was moved from x = 80 to x = 90, but snapped to x = 100. The user must drag either
      // 1 pixel left to exit snap on left, or drag 20 pixels (in total) right to exit snap on right
      //
      // Another example: say the entity was moved from x = 120 to x = 110, but snapped to x = 100. The user must drag either
      // 1 pixel right to exit snap on right, or drag 20 pixels (in total) left to exit snap on left
      if (snapEntry && Math.abs(snapEntry.dx + (snapEntry.originX - snapEntry.snapX)) > SNAPLINE_TOLERANCE_IN_PIXELS) {
        snapPosition.dx = snapEntry.dx + (snapEntry.originX - snapEntry.snapX);

        // clear snap entry for next time
        delete xSnapMap[snapKey];
      }
    } else {
      delete xSnapMap[snapKey];
    }

    const sortedSnapY = lodash.sortBy(snapY);
    if (sortedSnapY.length > 0) {
      snapPosition.y = sortedSnapY[0];

      // TODO - add starting clientX so this properly resets when new drag is begun.
      if (typeof ySnapMap[snapKey] === 'undefined') {
        ySnapMap[snapKey] = {
          originY: entityPosition.y + positionDelta.y,
          snapY: snapPosition.y,
          dy: 0,
        };
      } else {
        ySnapMap[snapKey].dy += positionDelta.y;
      }

      // set new default y position to snap position
      snapPosition.dy = snapPosition.y - entityPosition.y;

      const snapEntry = ySnapMap[snapKey];
      if (snapEntry && Math.abs(snapEntry.dy + (snapEntry.originY - snapEntry.snapY)) > SNAPLINE_TOLERANCE_IN_PIXELS) {
        snapPosition.dy = snapEntry.dy + (snapEntry.originY - snapEntry.snapY);

        // clear snap entry for next time
        delete ySnapMap[snapKey];
      }
    } else {
      delete ySnapMap[snapKey];
    }

    return snapPosition;
  }

  /**
   * Keeps the image's center coordinates the same as they were before to prevent weird resizing.
   *
   * @param {{}} entity
   * @param {{x: number, y: number, width: number, height: number}} safeImage
   * @returns {{x: number, y: number}}
   */
  // function enforceImageCenterOnResize(entity, safeImage) {
  //   const newItem = {...safeImage};
  //
  //   const currentImage = entity.get('image');
  //   const currentImageCenter = {
  //     x: currentImage.x + (currentImage.width / 2),
  //     y: currentImage.y + (currentImage.height / 2),
  //   };
  //   const newImageCenter = {
  //     x: safeImage.x + (safeImage.width / 2),
  //     y: safeImage.y + (safeImage.height / 2),
  //   };
  //
  //   // Keep the image center in the safe position.
  //   newItem.x = safeImage.x + (currentImageCenter.x - newImageCenter.x);
  //   newItem.y = safeImage.y + (currentImageCenter.y - newImageCenter.y);
  //
  //   return newItem;
  // }

  /**
   * Maintains the position of the crop window
   *
   * @param {{}} entity
   * @param {{x: number, y: number, width: number, height: number}} safeImage
   * @param {{x: number, y: number, width: number, height: number}} safeCrop
   * @param {{rotate: number}} safePosition
   * @returns {{x: number, y: number}}
   */
  // function maintainCropPosition(entity, safeImage, safeCrop, safePosition) {
  //   if (!safeCrop) {
  //     return safeCrop;
  //   }
  //
  //   const currentCrop = toJS(entity.get('crop'));
  //   const currentImage = entity.get('image');
  //   const currentPosition = entity.get('position');
  //
  //   const currentOrigin = {
  //     x: currentImage.x,
  //     y: currentImage.y,
  //   };
  //   const newOrigin = {
  //     x: safeImage.x,
  //     y: safeImage.y,
  //   };
  //
  //   const originRotated = mapShapeToRotatedFrame(currentPosition.rotate, currentCrop, currentOrigin);
  //   const newRotated = mapShapeToRotatedFrame(0 - safePosition.rotate, originRotated, newOrigin);
  //
  //   return newRotated;
  // }

  /**
   * Parses the position and size components based on the changes/deltas.
   *
   * @param {ObservableMap} actionEntity
   * @param {{}} entityPosition
   * @param {{}} entitySize
   * @returns {{safePosition: {}, safeSize: {}}}
   */
  function getNewPositionAndSizeV2(actionEntity, entityPosition, entitySize) {
    const {positionDelta, sizeDelta, newRotate} = getDeltasV2(actionEntity, entityPosition);

    const safePosition = lodash.cloneDeep(entityPosition);
    const safeSize = lodash.cloneDeep(entitySize);

    if (positionDelta && positionDelta.y) {
      safePosition.y += positionDelta.y;
    }
    if (positionDelta && positionDelta.x) {
      safePosition.x += positionDelta.x;
    }
    if (sizeDelta && sizeDelta.width) {
      safeSize.width += sizeDelta.width;
    }
    if (sizeDelta && sizeDelta.height) {
      safeSize.height += sizeDelta.height;
    }
    if (newRotate !== null && newRotate !== undefined) {
      safePosition.rotate = newRotate;
    }

    return {
      safePosition,
      safeSize,
    };
  }

  /**
   * Enforces that items can't be moved in any way outside of the game boundaries.
   *
   * @param {{height: number, width: number}} size
   * @param {{x: number, y: number}} position
   * @param {{height: number, width: number}} gameResolution
   * @returns {{x: number, y: number, truncateHeight: number, truncateWidth: number}}
   */
  // function enforceInsideBoundaries(size, position, gameResolution) { // eslint-disable-line no-unused-vars
  //   // Make sure the new position won't push the item off the display.
  //   let newY = position.y;
  //   if (newY < 0) {
  //     newY = 0;
  //   } else if (newY + size.height > gameResolution.height) {
  //     newY = gameResolution.height - size.height;
  //   }

  //   let newX = position.x;
  //   if (newX < 0) {
  //     newX = 0;
  //   } else if (newX + size.width > gameResolution.width) {
  //     newX = gameResolution.width - size.width;
  //   }

  //   return {
  //     y: newY,
  //     x: newX,
  //     truncateHeight: newY - position.y,
  //     truncateWidth: newX - position.x,
  //   };
  // }

  return {
    name: POSITIONING_SYSTEM,
    runActions: systemRunActions
  };
}

/**
 * Checks the cropping boundaries if the position change.
 * This makes sure the item doesn't entirely leave the viewing area, but allows it to drift outside a bit.
 *
 * @param {{height: number, width: number}} size
 * @param {{x: number, y: number}} position
 * @param {{x: string, y: string}} alignment
 * @param {{height: number, width: number}} gameResolution
 * @param {number=} sizePadding
 * @returns {{x: number, y: number, truncateHeight: number, truncateWidth: number}}
 */
function checkCroppingBoundaries(size, position, alignment, gameResolution, sizePadding) {
  const defaultPadding = 10;
  const padding = (sizePadding != null) ? sizePadding : defaultPadding;

  let limitTop = 0 - size.height + padding;
  let limitLeft = 0 - size.width + padding;
  let limitBottom = gameResolution.height - padding;
  let limitRight = gameResolution.width - padding;

  if (alignment.y === ALIGNMENTS.y.middle) {
    const adjustForMiddle = ((gameResolution.height - size.height) / 2);
    limitTop -= adjustForMiddle;
    limitBottom -= adjustForMiddle;
  }
  if (alignment.x === ALIGNMENTS.x.center) {
    const adjustForCenter = ((gameResolution.width - size.width) / 2);
    limitLeft -= adjustForCenter;
    limitRight -= adjustForCenter;
  }

  // Make sure the new position won't push the item fully off the display.
  let newY = position.y;
  if (newY < limitTop) {
    // This prevents the entity's bottom from leaving the top of the display.
    newY = limitTop;
  } else if (newY > limitBottom) {
    // This prevents the entity's top from leaving the bottom of the display.
    newY = limitBottom;
  } else if (size.height < padding) {
    // This prevents the entity's height from dropping below the padding when resizing from the top.
    newY = newY - (padding - size.height);
  }

  let newX = position.x;
  if (newX < limitLeft) {
    // This prevents the entity's right side from leaving the left side of the display.
    newX = limitLeft;
  } else if (newX > limitRight) {
    // This prevents the entity's left side from leaving the right side of the display.
    newX = limitRight;
  } else if (size.width < padding) {
    // This prevents the entity's width from dropping below the padding when resizing from the left.
    newX = newX - (padding - size.width);
  }

  return {
    y: newY,
    x: newX,
    truncateHeight: newY - position.y,
    truncateWidth: newX - position.x,
  };
}

/**
 * Check for additional updates to image entities
 *
 * @param {{}} groupPosition
 * @param {{}} newGroupPosition
 * @param {{}} groupSize
 * @param {{}} newGroupSize
 * @param {ObservableMap} entity
 * @param {boolean} moveCrop unused for now. see git history for past uses
 * @returns {{}}
 */
function checkImageUpdates(
  groupPosition,
  newGroupPosition,
  groupSize,
  newGroupSize,
  entity,
  // eslint-disable-next-line no-unused-vars
  moveCrop,
) {
  if (entity.get('element') !== 'image') {
    return {};
  }

  const safeImage = lodash.cloneDeep(entity.get('image'));
  const safeCrop = lodash.cloneDeep(entity.get('crop'));

  if (groupPosition && newGroupPosition) {
    const dx = newGroupPosition.x - groupPosition.x;
    const dy = newGroupPosition.y - groupPosition.y;

    safeImage.x += dx;
    safeImage.y += dy;
  }

  if (groupSize && newGroupSize) {
    const widthRatio = safeImage.width / groupSize.width;
    const heightRatio = safeImage.height / groupSize.height;

    safeImage.width = newGroupSize.width * widthRatio;
    safeImage.height = newGroupSize.height * heightRatio;
  }

  return {
    safeImage,
    safeCrop,
  };
}

/**
 * Gets whether or not the delta has any non-zero values.
 *
 * @param {Object.<string, number>} delta
 * @returns {boolean}
 */
function getDeltaHasChanges(delta) {
  return lodash.some(delta, (value) => {
    return Boolean(value);
  });
}
