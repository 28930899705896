/**
 * The action component.
 *
 * @param {{entityId: (string|string[])}} params
 * @returns {{action: {entityId: (string|string[]), time: number, skipHistory: boolean}}}
 */
export function actionComponent(params) {
  if (!(params.entityId || params.entityIds)) {
    throw new Error('Actions require an entityId or entityIds parameter.');
  }

  const baseAction = {
    action: {
      time: Date.now(),
      skipHistory: Boolean(params.skipHistory),
    },
  };

  if (params.entityIds) {
    baseAction.action.entityIds = params.entityIds;
    return baseAction;
  }

  baseAction.action.entityId = params.entityId;
  return baseAction;
}
