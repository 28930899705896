/* eslint-disable complexity */
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';

import SnapLinePath from '../common/SnapLinePath';
import {getBoundaries} from '../../../display/ecs/entityHelper';
import {isInRange} from '../../../utils/mathHelper';

const SNAPLINE_TOLERANCE_IN_PIXELS = 1;

/**
 * Multiplier for how much larger the svg viewBox will be than the display.
 * @const {number}
 */
const VIEW_BOX_MODIFIER = 3;

/**
 * The DisplaySnapLines component.
 */
class DisplaySnapLines extends React.Component {
  /**
   * Check if active entity is in proximity to other entities
   *
   * @returns {{}}
   */
  checkSnapLineProximity() {
    const {
      /** @type {ObservableMap} */ entities: activeEntities,
      /** @type {GameStore} */ game,
    } = this.props;

    const entities = game.entities || [];
    const otherEntities = entities.filter(
      (item) => !!item.get('position')
        && !!item.get('size')
        && !activeEntities.some((activeEntity) =>
          activeEntity.get('id') === item.get('id')
        )
    );

    const overflowModifier = 2;
    const overflowHeight = game.resolution.height * overflowModifier;
    const overflowWidth = game.resolution.width * overflowModifier;

    let activeEntityX = 0;
    let activeEntityY = 0;
    let activeEntityCenterX = 0;
    let activeEntityCenterY = 0;
    let activeEntityWidth = 0;
    let activeEntityHeight = 0;

    if (activeEntities.length === 1) {
      ({
        x: activeEntityX,
        y: activeEntityY,
      } = activeEntities[0].get('position'));
      ({
        width: activeEntityWidth,
        height: activeEntityHeight,
      } = activeEntities[0].get('size'));

      activeEntityCenterX = activeEntityX + (activeEntityWidth / 2);
      activeEntityCenterY = activeEntityY + (activeEntityHeight / 2);
    } else {
      ({
        position: activeEntityX,
        size: activeEntityWidth,
      } = getBoundaries(activeEntities, 'x', 'width', game.resolution));
      ({
        position: activeEntityY,
        size: activeEntityHeight,
      } = getBoundaries(activeEntities, 'y', 'height', game.resolution));

      activeEntityCenterX = activeEntityX + (activeEntityWidth / 2);
      activeEntityCenterY = activeEntityY + (activeEntityHeight / 2);
    }

    return otherEntities.reduce((acc, otherEntity) => {
      const {
        width: otherEntityWidth,
        height: otherEntityHeight,
      } = otherEntity.get('size');
      const {
        x: otherEntityX,
        y: otherEntityY,
      }  = otherEntity.get('position');
      const otherEntityCenterX = otherEntityX + (otherEntityWidth / 2);
      const otherEntityCenterY = otherEntityY + (otherEntityHeight / 2);
      const otherEntityId = otherEntity.get('id');

      /**
       * left edge
       */

      // check if active left is aligned to other left
      if (isInRange(
        activeEntityX,
        otherEntityX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityX}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_ll_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active left is aligned to other center
      if (isInRange(
        activeEntityX,
        otherEntityCenterX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityCenterX}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityCenterX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_lc_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active left is aligned to other right
      if (isInRange(
        activeEntityX,
        otherEntityX + otherEntityWidth - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + otherEntityWidth + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_lr_${otherEntityId}`}
            d={path}
          />
        );
      }

      /**
       * center x edge
       */

      // check if active center is aligned to other left
      if (isInRange(
        activeEntityCenterX,
        otherEntityX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityX}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_cl_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active center is aligned to other center
      if (isInRange(
        activeEntityCenterX,
        otherEntityCenterX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityCenterX}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityCenterX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_cc_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active center is aligned to other right
      if (isInRange(
        activeEntityCenterX,
        otherEntityX + otherEntityWidth - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + otherEntityWidth + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_cr_${otherEntityId}`}
            d={path}
          />
        );
      }

      /**
       * right edge
       */

      // check if active right is aligned to other left
      if (isInRange(
        activeEntityX + activeEntityWidth,
        otherEntityX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path = `M ${overflowWidth + otherEntityX}, ${overflowHeight + lowestY}
          L ${overflowWidth + otherEntityX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_rl_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active right is aligned to other center
      if (isInRange(
        activeEntityX + activeEntityWidth,
        otherEntityCenterX - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterX + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path =  `M ${overflowWidth + otherEntityCenterX}, ${overflowHeight + lowestY}
            L ${overflowWidth + otherEntityCenterX}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_rc_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active right is aligned to other right
      if (isInRange(
        activeEntityX + activeEntityWidth,
        otherEntityX + otherEntityWidth - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityX + otherEntityWidth + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestY = Math.min(activeEntityY, otherEntityY);
        const highestY = Math.max(activeEntityY + activeEntityHeight, otherEntityY + otherEntityHeight);

        const path =  `M ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + lowestY}
            L ${overflowWidth + otherEntityX + otherEntityWidth}, ${overflowHeight + highestY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_rr_${otherEntityId}`}
            d={path}
          />
        );
      }

      /**
       * top edge
       */

      // check if active top is aligned with other top
      if (isInRange(
        activeEntityY,
        otherEntityY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_tt_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active top is aligned with other center
      if (isInRange(
        activeEntityY,
        otherEntityCenterY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityCenterY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityCenterY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_tm_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active top is aligned with other bottom
      if (isInRange(
        activeEntityY,
        otherEntityY + otherEntityHeight - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + otherEntityHeight + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY + otherEntityHeight}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY + otherEntityHeight}`;

        acc.push(
          <SnapLinePath
            key={`snapline_tb_${otherEntityId}`}
            d={path}
          />
        );
      }

      /**
       * center y edge
       */

      // check if active center is aligned with other top
      if (isInRange(
        activeEntityCenterY,
        otherEntityY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_mt_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active center is aligned with other center
      if (isInRange(
        activeEntityCenterY,
        otherEntityCenterY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityCenterY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityCenterY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_mm_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active center is aligned with other bottom
      if (isInRange(
        activeEntityCenterY,
        otherEntityY + otherEntityHeight - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + otherEntityHeight + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY + otherEntityHeight}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY + otherEntityHeight}`;

        acc.push(
          <SnapLinePath
            key={`snapline_mb_${otherEntityId}`}
            d={path}
          />
        );
      }

      /**
       * bottom edge
       */

      // check if active bottom is aligned with other top
      if (isInRange(
        activeEntityY + activeEntityHeight,
        otherEntityY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_bt_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active bottom is aligned with other center
      if (isInRange(
        activeEntityY + activeEntityHeight,
        otherEntityCenterY - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityCenterY + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityCenterY}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityCenterY}`;

        acc.push(
          <SnapLinePath
            key={`snapline_bm_${otherEntityId}`}
            d={path}
          />
        );
      }

      // check if active bottom is aligned with other bottom
      if (isInRange(
        activeEntityY + activeEntityHeight,
        otherEntityY + otherEntityHeight - SNAPLINE_TOLERANCE_IN_PIXELS,
        otherEntityY + otherEntityHeight + SNAPLINE_TOLERANCE_IN_PIXELS
      )) {
        const lowestX = Math.min(activeEntityX, otherEntityX);
        const highestX = Math.max(activeEntityX + activeEntityWidth, otherEntityX + otherEntityWidth);

        const path = `M ${overflowWidth + lowestX}, ${overflowHeight + otherEntityY + otherEntityHeight}
          L ${overflowWidth + highestX}, ${overflowHeight + otherEntityY + otherEntityHeight}`;

        acc.push(
          <SnapLinePath
            key={`snapline_bb_${otherEntityId}`}
            d={path}
          />
        );
      }

      return acc;
    }, []);

    // check
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game} = this.props;

    const overflowModifier = 2;
    const overflowHeight = game.resolution.height * overflowModifier;
    const overflowWidth = game.resolution.width * overflowModifier;

    const svgStyle = {
      position: 'absolute',
      zIndex: 1000002,
      top: `${0 - overflowHeight}px`,
      left: `${0 - overflowWidth}px`,
      height: overflowHeight * VIEW_BOX_MODIFIER,
      width: overflowWidth * VIEW_BOX_MODIFIER,
    };

    // const outlineStyle = {
    //   top: (positionStyle.top || 0) + overflowHeight,
    //   left: (positionStyle.left || 0) + overflowWidth,
    //   height: (safeEntity.size) ? safeEntity.size.height : null,
    //   width: (safeEntity.size) ? safeEntity.size.width : null,
    // };

    // const rotateDegrees = 0;
    // const centerX = outlineStyle.left + (outlineStyle.width / 2);
    // const centerY = outlineStyle.top + (outlineStyle.height / 2);

    // const rotationStyle = {
    //   transform: `rotate(${rotateDegrees}deg)`,
    //   WebkitTransform: `rotate(${rotateDegrees}deg)`,
    //   transformOrigin: `${centerX}px ${centerY}px 0px`,
    // };

    const paths = this.checkSnapLineProximity();

    return (
      <svg
        id="display-snaplines"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        fill="none"
        stroke="none"
        strokeLinecap="square"
        strokeMiterlimit="10"
        overflow="hidden"
        preserveAspectRatio="none"
        pointerEvents="none"
        viewBox={`0 0 ${svgStyle.width} ${svgStyle.height}`}
        style={svgStyle}
      >
        <g>
          {paths}
        </g>
      </svg>
    );
  }
}

DisplaySnapLines.propTypes = {
  entities: PropTypes.arrayOf(MobxPropTypes.observableMap).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplaySnapLines);
