import lodash from 'lodash';
import {observable, action, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Alert} from 'reactstrap';

import ManufacturerOnboardingExperience from './components/onboardingExperiences/ManufacturerExperience';
import StandardOnboardingExperience from './components/onboardingExperiences/StandardExperience';
import ChargebeeCheckout from '../../common/chargebeeCheckout/ChargebeeCheckout';
import inject from '../../hoc/injectHoc';
import {dashboardRoute} from '../../routePaths';
import {PAYMENT_PLAN_MONTHLY} from '../../../constants/subscriptionPlanConstants';
import {ALA_CARTE_CREDITS} from '../../../constants/pricingConstants';

import './onboardingPage.scss';

/**
 * The OnboardingPage component.
 */
export class OnboardingPage extends React.Component {
  /**
   * The currently selected plan.
   * This will be selected in the SubscriptionPlanTable component.
   *
   * @type {object}
   */
  @observable currentPlan = null;

  /**
   * The starting plan id.
   * This will clear when the plan is selected.
   *
   * @type {?number}
   */
  @observable startingPlanId = null;

  /**
   * The a la carte credits the user wants to purchase
   *
   * @type {?{
   *  creditId: string,
   *  quantity: number
   * }}
   */
  @observable alaCarteCredits = null;

  /**
   * Chargebee Checkout is Loading
   *
   * @type {boolean}
   */
  @observable isLoadingChargebeeCheckout = false

  /**
   * Controls opening and closing chargebee checkout hosted page
   *
   * @type {boolean}
   */
  @observable chargebeeOpenCheckout = false;

  /**
   * Chargebee's hosted page details
   *
   * @type {{}}
   */
  @observable chargebeeHostedPage = null;

  /**
   * The order details billing type selected
   *
   * @type {string}
   */
  @observable billingType = PAYMENT_PLAN_MONTHLY;

  /**
   * Flag for if there is an error while loading chargebee checkout
   *
   * @type {boolean}
   */
  @observable hasChargebeeCheckoutError = false;

  /**
   * Flag for if user has no signs on submit
   *
   * @type {boolean}
   */
  @observable hasNoUserSignsError = false;

  /**
   * Flag for if user has already viewed the plan select pop-up
   *
   * @type {boolean}
   */
  @observable planSelectModalConfirmed = false;

  /**
   * Triggered when the component is first mounted to the page.
   */
  @action componentDidMount() {
    const {apiUserGetMeStore, apiUserOnboardStore, routerStore} = this.props;

    const startingPlanId = this.getPlanId();
    if (startingPlanId) {
      this.startingPlanId = startingPlanId;
    }

    apiUserOnboardStore.clearAll();

    apiUserGetMeStore.refresh(true);

    apiUserGetMeStore.getPromise().then((user) => {
      const planId = lodash.get(user, 'projectContentCompany.planId');
      if (planId && !user.requiresPlanUpdate) {
        // If the user has already onboarded, then send them to the dashboard.
        routerStore.push(dashboardRoute, {
          replaceIfTo: true,
        });
      }
    });
  }

  /**
   * Gets the plan id from the url params.
   *
   * @param {{match: {params: {pid: number}}}=} props
   * @returns {?number}
   */
  getPlanId = (props) => {
    const planId = lodash.get(props || this.props, 'match.params.planId');
    if (!planId || !Number(planId)) {
      return null;
    }

    return Number(planId);
  };

  /**
   * Change the currently selected plan.
   *
   * @param {{}} plan The plan object from the database.
   * @param {boolean | undefined} force Force the plan selection
   */
  @action onPlanSelect = (plan) => {
    this.currentPlan = plan;
    this.startingPlanId = null;

    this.props.onboardingStore.setPlanData(plan);
  };

  /**
   * On change billing type property
   *
   * @param {string} billingType
   */
  @action onChangeBillingType = (billingType) => {
    this.billingType = billingType;
  }

  /**
   * Set Ala Carte Credits
   *
   * @param {{}} alaCarteCredits
   */
  @action setAlaCarteCredits = (alaCarteCredits) => {
    this.alaCarteCredits = alaCarteCredits;
  }

  /**
   * Control the Chargebee Checkout process
   */
  @action checkoutWithChargebee = async () => {
    const {apiUserOnboardStore, apiCompanySignGetAllStore} = this.props;

    const userSigns = apiCompanySignGetAllStore.getFulfilled() || [];

    // reset errors
    this.hasNoUserSignsError = false;
    this.hasChargebeeCheckoutError = false;

    if (userSigns.length === 0 && (this.currentPlan && this.currentPlan.canManageSigns)) {
      this.hasNoUserSignsError = true;
      return;
    }

    let data = {
      subscription: null,
      addons: null,
    };

    if (this.currentPlan && this.currentPlan.id) {
      data.subscription = {
        planId: this.currentPlan.id,
        billingType: this.billingType,
      };
    }

    if (this.alaCarteCredits) {
      data.addons = [{
        itemId: ALA_CARTE_CREDITS.download.chargebeeId,
        quantity: this.alaCarteCredits.download
      }, {
        itemId: ALA_CARTE_CREDITS.request.chargebeeId,
        quantity: this.alaCarteCredits.request
      }];
    }

    try {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = true;
      });
      const chargebeeCheckoutResponse = await apiUserOnboardStore.chargebeeCheckout(data);

      runInAction('Set the chargebee hosted page.', () => {
        this.chargebeeHostedPage = chargebeeCheckoutResponse;
        this.chargebeeOpenCheckout = true;
      });
    } catch (error) {
      runInAction('Error loading chargebee hosted page.', () => {
        // eslint-disable-next-line no-console
        console.log('Chargebee Error: ', error);
        this.hasChargebeeCheckoutError = true;
      });
    } finally {
      runInAction('Set the Chargebee Checkout Loading', () => {
        this.isLoadingChargebeeCheckout = false;
      });
    }
  }

  @action onSuccessfulChargebeeCheckout = () => {
    const {apiUserOnboardStore, onboardingStore, routerStore, apiUserPollMeStore} = this.props;
    this.chargebeeOpenCheckout = false;

    // Call the server onboarding.
    apiUserOnboardStore.makeRequest(
      onboardingStore.getDataForServer()
    );

    apiUserPollMeStore.pollThenUpdateMyUser();

    apiUserOnboardStore.getPromise().then(() => {
      routerStore.push(dashboardRoute, {
        replaceIfTo: true,
      });
    });
  }

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {apiUserGetMeStore, apiCompanySignGetAllStore} = this.props;
    const user = apiUserGetMeStore.getFulfilled();
    const userSigns = apiCompanySignGetAllStore.getFulfilled();
    const companyManufacturerSignupType = lodash.get(user, 'company.manufacturerSignupType');

    return (
      <div id="onboarding-page" className="system-page full-height container-fluid">
        {(user && companyManufacturerSignupType) && (<ManufacturerOnboardingExperience
          billingType={this.billingType}
          onChangeBillingType={this.onChangeBillingType}
          onPlanSelect={this.onPlanSelect}
          currentPlan={this.currentPlan || {id: this.startingPlanId}}
          user={user}
          userSigns={userSigns}
          checkoutWithChargebee={this.checkoutWithChargebee}
          isLoadingChargebeeCheckout={this.isLoadingChargebeeCheckout}
        />)}
        {(user && !companyManufacturerSignupType) && (<StandardOnboardingExperience
          billingType={this.billingType}
          alaCarteCredits={this.alaCarteCredits}
          setAlaCarteCredits={this.setAlaCarteCredits}
          onChangeBillingType={this.onChangeBillingType}
          onPlanSelect={this.onPlanSelect}
          currentPlan={this.currentPlan || {id: this.startingPlanId}}
          user={user}
          userSigns={userSigns}
          checkoutWithChargebee={this.checkoutWithChargebee}
          isLoadingChargebeeCheckout={this.isLoadingChargebeeCheckout}
        />)}
        <ChargebeeCheckout
          openCheckout={this.chargebeeOpenCheckout}
          hostedPage={this.chargebeeHostedPage}
          onSuccessfulCheckout={this.onSuccessfulChargebeeCheckout}
        />
        <div className="container">
          {(this.hasChargebeeCheckoutError) && (
            <Alert color="danger" className="signup-alert">
              There was an error loading the checkout page. Please try again. If the problem continues contact us at info@projectcontent.com
            </Alert>
          )}

          {(this.hasNoUserSignsError) && (
            <Alert color="danger" className="signup-alert">
              Please build out your sign profile by clicking + in order to proceed with a purchase.
            </Alert>
          )}
        </div>
      </div>
    );
  }
}

OnboardingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }).isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  apiUserOnboardStore: MobxPropTypes.observableObject,
  apiUserPollMeStore: MobxPropTypes.observableObject,
  onboardingStore: MobxPropTypes.observableObject,
  routerStore: MobxPropTypes.observableObject,
};

OnboardingPage.wrappedComponent = {};
OnboardingPage.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  apiUserOnboardStore: MobxPropTypes.observableObject.isRequired,
  apiUserPollMeStore: MobxPropTypes.observableObject.isRequired,
  onboardingStore: MobxPropTypes.observableObject.isRequired,
  routerStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(OnboardingPage)(
  observer(OnboardingPage)
);
