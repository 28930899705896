import lodash from 'lodash';
import {runInAction} from 'mobx';

import {actionPositionComponent} from '../components/action/actionPositionComponent';
import {
  adjustForAlignment,
  getBoundaries,
  getEntityGroup,
  getEntityPosition,
  getEntityPositionAlignment,
  getEntitySize,
  getZonesArray
} from '../ecs/entityHelper';
import * as option from '../../constants/entityOptionConstants';
import {ALIGN_FAR, ALIGN_CENTERED, ALIGN_NEAR} from '../../constants/entityConstants';

/**
 * The name of the system.
 * @const {string}
 */
export const ALIGNMENT_SYSTEM = 'alignmentSystem';

/**
 * Gets a new instance of the alignment system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function alignmentSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    runInAction('alignmentSystemUpdateEntity', () => {
      actions.forEach((actionEntity) => {
        if (!actionEntity.has('actionAlign')) {
          return;
        }

        const action = actionEntity.get('action');
        const activeEntityIds = action.entityId;

        if (!activeEntityIds || typeof activeEntityIds === 'string') {
          return;
        } else if (!activeEntityIds.length) {
          return;
        }

        const activeEntities = lodash.intersectionBy(entities, activeEntityIds, (item) => {
          if (lodash.isString(item)) {
            return item;
          }
          return item.get('id');
        });

        if (!activeEntities) {
          return;
        }

        const actionAlign = actionEntity.get('actionAlign');
        const alignment = actionAlign.alignment;
        const alignmentTarget = actionAlign.alignmentTarget;
        const resolution = game.resolution;

        const positions = getNewPositions(activeEntities, alignment, alignmentTarget, resolution);
        if (!positions) {
          return;
        }

        positions.forEach((item) => {
          const moveCrop = true;
          const shouldSnap = false;

          game.addAction(
            {entityId: item.entity.get('id')},
            actionPositionComponent(false, item.positionDelta, item.sizeDelta || null, null, moveCrop, shouldSnap)
          );
        });
      });
    });
  }

  /**
   * Gets the new positions for the entities.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {string} alignmentTarget
   * @param {{height: number, width: number}} resolution
   * @returns {?Array.<{entity: {}, positionDelta: {}, sizeDelta: {}}>}
   */
  function getNewPositions(activeEntities, alignment, alignmentTarget, resolution) {
    if (
      lodash.includes([option.ALIGN_LEFT, option.ALIGN_TOP], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_SELECTION
    ) {
      return alignLeftOrTopToSelection(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.ALIGN_RIGHT, option.ALIGN_BOTTOM], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_SELECTION
    ) {
      return alignRightOrBottomToSelection(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.ALIGN_CENTER, option.ALIGN_MIDDLE], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_SELECTION
    ) {
      return alignCenterOrMiddleToSelection(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.ALIGN_LEFT, option.ALIGN_TOP], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_CANVAS
    ) {
      return alignLeftOrTopToCanvas(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.ALIGN_RIGHT, option.ALIGN_BOTTOM], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_CANVAS
    ) {
      return alignRightOrBottomToCanvas(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.ALIGN_CENTER, option.ALIGN_MIDDLE], alignment)
      && alignmentTarget === option.ALIGNMENT_TARGET_CANVAS
    ) {
      return alignCenterOrMiddleToCanvas(activeEntities, alignment, resolution);
    } else if (
      lodash.includes([option.DISTRIBUTE_HORIZONTALLY, option.DISTRIBUTE_VERTICALLY], alignment)
    ) {
      return distributeItems(activeEntities, alignment, alignmentTarget, resolution);
    } else if (lodash.includes([option.FIT_FULL, option.FIT_CENTER_HALF], alignment)) {
      return expandToFit(activeEntities, alignment, resolution);
    }

    return null;
  }

  /**
   * Gets the active entities that are in the same group
   *
   * @param {object[]} activeEntities
   * @param {string} entityGroupId
   * @returns {object[]}
   */
  function getActiveEntitiesInGroup(activeEntities, entityGroupId) {
    return activeEntities.filter((activeEntity) => {
      const activeEntityGroup = getEntityGroup(activeEntity);
      return activeEntityGroup && activeEntityGroup.id === entityGroupId;
    });
  }

  /**
   * Aligns the items to the left or top of selection boundaries
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignLeftOrTopToSelection(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_LEFT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_LEFT === alignment) ? 'width' : 'height';

    const farStart = getBoundaries(activeEntities, positionProp, sizeProp, resolution).start[positionProp];

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);
      let entityFarStart = farStart;
      const entityGroup = getEntityGroup(entity);
      if (entityGroup && entityGroup.id) {
        const activeEntitiesInGroup = getActiveEntitiesInGroup(activeEntities, entityGroup.id);
        const groupBoundaryStart = getBoundaries(activeEntitiesInGroup, positionProp, sizeProp, resolution).start[positionProp];

        entityFarStart = position[positionProp] - (groupBoundaryStart - farStart);
      }

      const newValue = adjustForAlignment(
        entityAlignment,
        entityFarStart,
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        if (activeEntities.length >= 2) {
          positionDelta = position[positionProp] - newValue;
        } else {
          positionDelta = position[positionProp] - newValue;
        }
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items as unit to the left or top of canvas
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignLeftOrTopToCanvas(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_LEFT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_LEFT === alignment) ? 'width' : 'height';

    const startOfCanvas = 0;

    const {start} = getBoundaries(activeEntities, positionProp, sizeProp, resolution);

    const positionDelta = startOfCanvas - start[positionProp];

    return activeEntities.map((entity) => {
      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items to the right or bottom of selection boundaries
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignRightOrBottomToSelection(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_RIGHT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_RIGHT === alignment) ? 'width' : 'height';

    const end = getBoundaries(activeEntities, positionProp, sizeProp, resolution).end;
    const farEnd = end[positionProp] + end[sizeProp];

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);
      let entityFarEnd = farEnd - size[sizeProp];
      const entityGroup = getEntityGroup(entity);
      if (entityGroup && entityGroup.id) {
        const activeEntitiesInGroup = getActiveEntitiesInGroup(activeEntities, entityGroup.id);
        const groupBoundaryEnd = getBoundaries(activeEntitiesInGroup, positionProp, sizeProp, resolution).end;
        const groupEndPosition = (groupBoundaryEnd[positionProp] + groupBoundaryEnd[sizeProp] - size[sizeProp]);
        const farEndDelta = (farEnd - groupEndPosition);
        const entityEndPosition = (position[positionProp] - size[sizeProp]);
        entityFarEnd = farEndDelta + entityEndPosition;
      }

      const newValue = adjustForAlignment(
        entityAlignment,
        entityFarEnd,
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_FAR) {
        if (activeEntities.length >= 2) {
          positionDelta = 0 - newValue + position[positionProp];
        } else {
          positionDelta = newValue + position[positionProp];
        }
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items as unit to the right or bottom of canvas
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignRightOrBottomToCanvas(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_RIGHT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_RIGHT === alignment) ? 'width' : 'height';

    const endOfCanvas = resolution[sizeProp];

    const {start, size} = getBoundaries(activeEntities, positionProp, sizeProp, resolution);

    const positionDelta = endOfCanvas - size - start[positionProp];

    return activeEntities.map((entity) => {
      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items to the center or middle of the selection boundaries
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignCenterOrMiddleToSelection(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_CENTER === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_CENTER === alignment) ? 'width' : 'height';

    const boundaryItems = getBoundaries(activeEntities, positionProp, sizeProp, resolution);
    const start = boundaryItems.start;
    const end = boundaryItems.end;

    const farStart = start[positionProp];
    const farEnd = end[positionProp] + end[sizeProp];

    const newCenter = ((farEnd - farStart) / 2) + farStart;

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);
      let entityCenter = newCenter - (size[sizeProp] / 2);
      const entityGroup = getEntityGroup(entity);
      if (entityGroup && entityGroup.id) {
        const activeEntitiesInGroup = getActiveEntitiesInGroup(activeEntities, entityGroup.id);
        const groupBoundaryItems = getBoundaries(activeEntitiesInGroup, positionProp, sizeProp, resolution);
        const groupStart = groupBoundaryItems.start[positionProp];
        const groupEnd = groupBoundaryItems.end[positionProp] + groupBoundaryItems.end[sizeProp] - size[sizeProp];
        const groupCenter = ((groupEnd - groupStart) / 2) + groupStart;
        const groupDelta = newCenter - groupCenter;
        entityCenter = groupDelta + position[positionProp] - (size[sizeProp] / 2);
      }

      const newValue = adjustForAlignment(
        entityAlignment,
        entityCenter,
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        positionDelta = position[positionProp] - newValue;
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items as unit to the center (horizontally) or middle (vertically) of canvas
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignCenterOrMiddleToCanvas(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_CENTER === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_CENTER === alignment) ? 'width' : 'height';

    const middleOfCanvas = resolution[sizeProp] / 2;

    const {start, size} = getBoundaries(activeEntities, positionProp, sizeProp, resolution);

    const positionDelta = middleOfCanvas - start[positionProp] - (size / 2);

    return activeEntities.map((entity) => {
      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Distributes the items evenly either horizontally or vertically to alignment target
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {string} alignmentTarget
   * @param {{height: number, width: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function distributeItems(activeEntities, alignment, alignmentTarget, resolution) {
    const positionProp = (option.DISTRIBUTE_HORIZONTALLY === alignment) ? 'x' : 'y';
    const sizeProp = (option.DISTRIBUTE_HORIZONTALLY === alignment) ? 'width' : 'height';

    let farStart = 0;
    let farEnd = 0;

    // if alignment target is selection, use start & end of boundary
    if (alignmentTarget === option.ALIGNMENT_TARGET_SELECTION) {
      const {start, end} = getBoundaries(activeEntities, positionProp, sizeProp, resolution);
      farStart = start[positionProp];
      farEnd = end[positionProp] + end[sizeProp];
    } else {
      // else use start & end of canvas
      farStart = 0;
      farEnd = resolution[sizeProp];
    }

    // 1. get all zones (non grouped entities and groups)
    const zones = getZonesArray(activeEntities);

    // if a single zone spans the entire sizeProp,
    // the items are already considered distributed.
    // So exit function and do not move any entities
    const singleZoneSpansEntireSize = zones.some((zone) => {
      switch (zone.type) {
        case 'entity': {
          const size = getEntitySize(zone.entity)[sizeProp];
          const position = getEntityPosition(zone.entity)[positionProp];

          return farStart === position && farEnd === position + size;
        }
        case 'group': {
          const {
            start: groupStart,
            end: groupEnd,
          } = getBoundaries(zone.entities, positionProp, sizeProp, resolution);

          return farStart === groupStart[positionProp] && farEnd === groupEnd[positionProp] + groupEnd[sizeProp];
        }
        default:
          return false;
      }
    });
    if (singleZoneSpansEntireSize) {
      return null;
    }

    // 2. calculate padding
    const sizeSum = zones.reduce((acc, zone) => {
      switch (zone.type) {
        case 'entity': {
          const size = getEntitySize(zone.entity)[sizeProp];
          return acc + size;
        }
        case 'group': {
          const {
            start: groupStart,
            end: groupEnd,
          } = getBoundaries(zone.entities, positionProp, sizeProp, resolution);
          const groupSize = (groupEnd[positionProp] + groupEnd[sizeProp]) - groupStart[positionProp];

          return acc + groupSize;
        }
        default:
          return acc;
      }
    }, 0);

    const totalPadding = (farEnd - farStart) - sizeSum;
    const numberOfSpaces = zones.length - 1;
    const newPadding = totalPadding / numberOfSpaces;

    // 3. order zones
    const orderedZones = lodash.sortBy(zones, (zone) => {
      const positionOffset = 100000;

      switch (zone.type) {
        case 'entity': {
          const entity = zone.entity;
          const entityAlignment = getEntityPositionAlignment(entity, positionProp);
          const entityPosition = getEntityPosition(entity)[positionProp];
          const entitySize = getEntitySize(entity)[sizeProp];

          const sizeFloor = Math.floor(entitySize);
          const positionFloor = Math.floor(adjustForAlignment(
            entityAlignment,
            entityPosition,
            entitySize,
            resolution[sizeProp]
          ));

          // Should still not overflow even for 32 bit computers unless the numbers are in the 50,000 range.
          // Allows for multi-sorting for position and size (sending array of values doesn't work for some reason).
          return (positionFloor * positionOffset) + sizeFloor;
        }
        case 'group': {
          const groupAlignment = ALIGN_NEAR;
          const {
            start: groupStart,
            end: groupEnd,
          } = getBoundaries(zone.entities, positionProp, sizeProp, resolution);
          const groupPosition = groupStart[positionProp];
          const groupSize = (groupEnd[positionProp] + groupEnd[sizeProp]) - groupStart[positionProp];

          const sizeFloor = Math.floor(groupSize);
          const positionFloor = Math.floor(adjustForAlignment(
            groupAlignment,
            groupPosition,
            groupSize,
            resolution[sizeProp]
          ));

          // Should still not overflow even for 32 bit computers unless the numbers are in the 50,000 range.
          // Allows for multi-sorting for position and size (sending array of values doesn't work for some reason).
          return (positionFloor * positionOffset) + sizeFloor;
        }
        default:
          return 0;
      }
    });

    // 4. update entity positions. if entity is grouped. update entity based on new grouped position
    let ongoingStart = farStart;
    return orderedZones.reduce((acc, zone) => {
      switch (zone.type) {
        case 'entity': {
          const entity = zone.entity;
          const position = getEntityPosition(entity);
          const size = getEntitySize(entity);
          const entityAlignment = getEntityPositionAlignment(entity, positionProp);
          const newValue = adjustForAlignment(
            entityAlignment,
            ongoingStart,
            size[sizeProp],
            resolution[sizeProp],
            true
          );

          let positionDelta;
          if (entityAlignment === ALIGN_CENTERED) {
            positionDelta = newValue - position[positionProp];
          } else if (entityAlignment === ALIGN_FAR) {
            positionDelta = position[positionProp] - newValue;
          } else {
            positionDelta = newValue - position[positionProp];
          }

          ongoingStart += newPadding + size[sizeProp];

          acc.push({
            entity,
            positionDelta: {
              [positionProp]: positionDelta,
            },
          });
          break;
        }
        case 'group': {
          const {
            start: groupStart,
            end: groupEnd,
          } = getBoundaries(zone.entities, positionProp, sizeProp, resolution);
          const groupPosition = groupStart[positionProp];
          const groupSize = (groupEnd[positionProp] + groupEnd[sizeProp]) - groupStart[positionProp];
          const groupAlignment = ALIGN_NEAR;

          const newValue = adjustForAlignment(
            groupAlignment,
            ongoingStart,
            groupSize,
            resolution[sizeProp],
            true
          );

          let positionDelta;
          if (groupAlignment === ALIGN_CENTERED) {
            positionDelta = newValue - groupPosition;
          } else if (groupAlignment === ALIGN_FAR) {
            positionDelta = groupPosition - newValue;
          } else {
            positionDelta = newValue - groupPosition;
          }

          ongoingStart += newPadding + groupSize;

          const entityDeltas = zone.entities.map((entity) => ({
            entity,
            positionDelta: {
              [positionProp]: positionDelta,
            },
          }));
          acc.push(...entityDeltas);
          break;
        }
        default:

          // do nothing
      }

      return acc;
    }, []);
  }

  /**
   * Expands the item to fit on the screen.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}, sizeDelta: {}}>}
   */
  function expandToFit(activeEntities, alignment, resolution) {
    const quarter = 4;
    const newValueX = (option.FIT_FULL === alignment) ? 0 : (resolution.width / quarter);
    const newValueY = (option.FIT_FULL === alignment) ? 0 : (resolution.height / quarter);

    const newValueWidth = (option.FIT_FULL === alignment) ? resolution.width : (resolution.width / 2);
    const newValueHeight = (option.FIT_FULL === alignment) ? resolution.height : (resolution.height / 2);

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignmentX = getEntityPositionAlignment(entity, 'x');
      const entityAlignmentY = getEntityPositionAlignment(entity, 'y');

      const adjustedPositionX = adjustForAlignment(
        entityAlignmentX,
        newValueX,
        newValueWidth,
        resolution.width,
        true
      );
      const adjustedPositionY = adjustForAlignment(
        entityAlignmentY,
        newValueY,
        newValueHeight,
        resolution.height,
        true
      );

      let positionDeltaX;
      if (entityAlignmentX === ALIGN_CENTERED) {
        positionDeltaX = adjustedPositionX - position.x;
      } else if (entityAlignmentX === ALIGN_FAR) {
        positionDeltaX = position.x - adjustedPositionX;
      } else {
        positionDeltaX = adjustedPositionX - position.x;
      }

      let positionDeltaY;
      if (entityAlignmentY === ALIGN_CENTERED) {
        positionDeltaY = adjustedPositionY - position.y;
      } else if (entityAlignmentY === ALIGN_FAR) {
        positionDeltaY = position.y - adjustedPositionY;
      } else {
        positionDeltaY = adjustedPositionY - position.y;
      }

      return {
        entity,
        positionDelta: {
          x: positionDeltaX,
          y: positionDeltaY,
        },
        sizeDelta: {
          width: (newValueWidth - size.width),
          height: (newValueHeight - size.height),
        },
      };
    });
  }

  return {
    name: ALIGNMENT_SYSTEM,
    runActions: systemRunActions
  };
}
