import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import EditorSidebarTitle from '../../common/editorSidebarTitle/EditorSidebarTitle';
import AlignEntitiesButtons from './components/alignEntitiesButtons/AlignEntitiesButtons';
import GroupEntitiesButtons from './components/groupEntitiesButtons/GroupEntitiesButtons';
import EditTransitionControl from '../editTransition/EditTransitionControl';

import './editMultipleControls.scss';

/**
 * Transitions are too complicated to allow a multi-edit, so for now they are disabled.
 *
 * @type {boolean}
 */
const allowTransitionEdit = false;

/**
 * The EditMultipleControls component.
 */
export class EditMultipleControls extends React.Component {
  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {
      /** @type {?ObservableMap[]} */ entities,
      /** @type {GameStore} */ game
    } = this.props;

    if (game.composeMode) {
      // Compose mode disables most editing abilities.
      return null;
    }

    const hasTimeLine = game.hasTimeLine();

    return (
      <div className="edit-multiple-controls">
        <div className="multiple-group">
          <EditorSidebarTitle title="Multiple Selected" />
          <div className="group-header">
            <span className="group-header-label">Alignment</span>
          </div>
          <div className="group-controls">
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <AlignEntitiesButtons
                    id="alignment-entities"
                    game={game}
                    single={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <GroupEntitiesButtons game={game} />

        {(allowTransitionEdit && hasTimeLine) && (
          <div>
            <div className="group-header">
              <span className="group-header-label">Transitions</span>
            </div>
            <div className="group-controls">
              <div className="row">
                <div className="col">
                  <EditTransitionControl entities={entities} game={game} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

EditMultipleControls.propTypes = {
  entities: PropTypes.arrayOf(MobxPropTypes.observableMap).isRequired,
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(EditMultipleControls);
