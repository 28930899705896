/**
 * The action align component.
 *
 * @param {string} alignment
 * @param {string} alignmentTarget
 * @returns {{actionAlign: {alignment: string}}}
 */
export function actionAlignComponent(alignment, alignmentTarget) {
  return {
    actionAlign: {
      alignment,
      alignmentTarget,
    },
  };
}
