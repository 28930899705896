import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import React from 'react';

import DisplayActive from '../displayActive/DisplayActive';
import DisplayCrop from '../displayCrop/DisplayCrop';
import DisplayEntity from '../displayEntity/DisplayEntity';
import DisplayHightlight from '../displayHighlight/DisplayHighlight';
import DisplayMultiActive from '../displayMultiActive/DisplayMultiActive';
import {getIsSizeLocked} from '../../../utils/dragDropHelper';
import DisplaySnapLines from '../displaySnapLines/DisplaySnapLines';

import './displayEntities.scss';

/**
 * The DisplayEntities component.
 */
class DisplayEntities extends React.Component {
  /**
   * Whether or not to show the active outline.
   *
   * @param {?{}} activeEntity
   * @returns {boolean}
   */
  shouldShowActiveOutline = (activeEntity) => {
    if (!activeEntity) {
      return false;
    }

    if (!activeEntity.has('visible') || !activeEntity.get('visible').isVisible) {
      return false;
    } else if (activeEntity.get('element') === 'line') {
      return false;
    }

    return true;
  };

  /**
   * Whether or not to show the crop outline.
   *
   * @param {?{}} activeEntity
   * @returns {boolean}
   */
  shouldShowCropOutline = (activeEntity) => {
    if (!activeEntity) {
      return false;
    }

    if (!activeEntity.has('visible') || !activeEntity.get('visible').isVisible) {
      return false;
    } else if (activeEntity.get('element') === 'line') {
      return false;
    } else if (!activeEntity.get('interaction').isCropping) {
      return false;
    }

    return true;
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {game} = this.props;
    const entities = game.entities || [];

    const safeActiveEntities = game.allActiveEntities.filter((entity) => {
      if (!entity.has('visible') || !entity.get('visible').isVisible) {
        return false;
      } else if (entity.get('element') === 'line') {
        return false;
      } else if (getIsSizeLocked(entity)) {
        return false;
      }
      return true;
    });

    const activeCount = safeActiveEntities.length;
    const firstActiveEntity = safeActiveEntities[0];
    const showCropOutline = (activeCount === 1) ? this.shouldShowCropOutline(firstActiveEntity) : false;
    const showSnapLines = game.isDragging;

    return (
      <div id="display-entities">
        {entities.map((entity) => (
          <DisplayEntity
            key={entity.get('id')}
            entity={entity}
            game={game}
          />
        ))}

        {(activeCount === 1 && this.shouldShowActiveOutline(firstActiveEntity)) && (
          <DisplayActive entity={firstActiveEntity} game={game} />
        )}

        {(activeCount > 1) && safeActiveEntities.map((activeEntity) => {
          if (!this.shouldShowActiveOutline(activeEntity)) {
            return null;
          }
          return (
            <DisplayHightlight key={activeEntity.get('id')} entity={activeEntity} game={game} />
          );
        })}

        {(activeCount > 1) && (
          <DisplayMultiActive game={game} activeEntities={safeActiveEntities} />
        )}

        {(showCropOutline) && (
          <DisplayCrop entity={firstActiveEntity} game={game} />
        )}

        {(showSnapLines && activeCount > 0) && (
          <DisplaySnapLines entities={safeActiveEntities} game={game} />
        )}
      </div>
    );
  }
}

DisplayEntities.propTypes = {
  game: MobxPropTypes.observableObject.isRequired,
};

export default observer(DisplayEntities);
