export const REALTIME_CURRENT_WEATHER = 'Weather';
export const REALTIME_CURRENT_TIME = 'CurrentTime';

export const REALTIME_FORECAST_DAY_ONE = 'ForecastDay1';
export const REALTIME_FORECAST_DAY_TWO = 'ForecastDay2';
export const REALTIME_FORECAST_DAY_THREE = 'ForecastDay3';
export const REALTIME_FORECAST_ICON_ONE = 'ForecastIcon1';
export const REALTIME_FORECAST_ICON_TWO = 'ForecastIcon2';
export const REALTIME_FORECAST_ICON_THREE = 'ForecastIcon3';
export const REALTIME_FOREACST_LOW_ONE = 'ForecastLow1';
export const REALTIME_FOREACST_LOW_TWO = 'ForecastLow2';
export const REALTIME_FOREACST_LOW_THREE = 'ForecastLow3';
export const REALTIME_FOREACST_HIGH_ONE = 'ForecastHigh1';
export const REALTIME_FOREACST_HIGH_TWO = 'ForecastHigh2';
export const REALTIME_FOREACST_HIGH_THREE = 'ForecastHigh3';

export const REALTIME_FORECAST_TYPES = [
  REALTIME_FORECAST_DAY_ONE,
  REALTIME_FORECAST_DAY_TWO,
  REALTIME_FORECAST_DAY_THREE,
  REALTIME_FORECAST_ICON_ONE,
  REALTIME_FORECAST_ICON_TWO,
  REALTIME_FORECAST_ICON_THREE,
  REALTIME_FOREACST_LOW_ONE,
  REALTIME_FOREACST_LOW_TWO,
  REALTIME_FOREACST_LOW_THREE,
  REALTIME_FOREACST_HIGH_ONE,
  REALTIME_FOREACST_HIGH_TWO,
  REALTIME_FOREACST_HIGH_THREE,
];

export const REALTIME_COUNTDOWN = 'Countdown';
export const REALTIME_COUNTUP = 'Countup';

export const REALTIME_TYPES = [
  REALTIME_CURRENT_WEATHER,
  REALTIME_CURRENT_TIME,
  ...REALTIME_FORECAST_TYPES,
  REALTIME_COUNTDOWN,
  REALTIME_COUNTUP,
];
